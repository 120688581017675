import React, { useEffect, useState } from 'react'
import useFormData from 'hooks/formData'
import { DefaultCollectionTypes, SaatchiLatestCollectionTypes } from 'components/Collection/Data'
import CheckboxV2 from 'components/Form/CheckboxV2'
import Radio from 'components/Form/Radio'
import { CollectionFormDefaults, AA_SELECTION_TYPE } from './constants'
import { formValidationRules } from './validation'
import Input from './Input'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyColor, SATypographyVariant } from 'components/SATypography/SATypography.model'
import styles from './Form.module.scss'
import classNames from 'classnames'
import { SAButton } from 'components/SAButton/SAButton'

const CreateCollectionV2 = (props: Record<string, any>) => {
  const { createCollection, isAdmin, isSubmitting, closeModal } = props
  const [noValidate, setNoValidate] = useState(false)
  const {
    formData,
    formErrors,
    updateFormData,
    onChangeInput,
    onChangeCheckbox,
    validateFormData,
  } = useFormData({
    initialState: CollectionFormDefaults,
    formRules: formValidationRules,
  })
  const {
    collectionTypeIds,
    description,
    email,
    firstName,
    header,
    lastName,
    privacy,
    subtitle,
    title,
    visible,
    zendeskTicketId,
    printsView,
  } = formData
  useEffect(() => {
    updateFormData({ ...CollectionFormDefaults, isSetup: true })
  }, [])
  const isAACollection = collectionTypeIds.includes(AA_SELECTION_TYPE)
  useEffect(() => {
    updateFormData({
      visible: isAACollection ? '0' : '1',
    })
  }, [isAACollection])

  // Make sure we have Initial Payload/FormData `isSetup`
  if (!formData.isSetup) {
    return null
  }

  const onSubmit = async (ev: React.SyntheticEvent<HTMLElement>) => {
    ev.preventDefault()

    // We will use a hook to track local data VS lots of updates in Epics/Redux
    // When we are finally ready to SUBMIT we will UPDATE via Epics/Redux
    // We use the Redux FormData in Epics to validate and track Errors
    if (!noValidate) {
      const isValid = await validateFormData()

      if (isValid) {
        createCollection(formData)
      }
    }
  }

  const collectionIdErrors = formErrors.collectionTypeIds

  return (
    <div className={styles.formContainer}>
      <form id='createCollection' name='createCollection' autoComplete='off'>
        <SATypography variant={SATypographyVariant.H4}>Create A Collection</SATypography>
        <div
          data-type='info-div'
          className={classNames(styles.collectionInfoDiv, {
            [styles.collectionInfoDivNonAdmin]: !isAdmin,
          })}
        >
          <div className={styles.flexHalfColumn}>
            <Input
              error={formErrors.title}
              label='Collection Title'
              name='title'
              onChange={onChangeInput}
              value={title}
              isInline={!isAdmin}
            />

            {isAdmin && (
              <Input
                error={formErrors.header}
                label='Collection Header'
                name='header'
                onChange={onChangeInput}
                value={header}
              />
            )}

            {isAdmin && (
              <Input
                error={formErrors.subtitle}
                label='Collection Subtitle'
                name='subtitle'
                onChange={onChangeInput}
                value={subtitle}
                customFieldsetBoxContainerStyles={{ gap: '24px' }}
              />
            )}
          </div>
          <div className={styles.flexHalfColumn}>
            <Input
              error={formErrors.description}
              label='Collection Description'
              name='description'
              onChange={onChangeInput}
              value={description}
              isTextArea
              customFieldsetBoxStyles={{ flex: 2 }}
              isInline={!isAdmin}
            />

            <fieldset
              className={classNames(styles.fieldsetBox, styles.titleInputBox, {
                [styles.titleInputBoxInline]: !isAdmin,
                [styles.makeCollectionBoxInline]: !isAdmin,
              })}
            >
              <SATypography
                customDivClassName={!isAdmin ? styles.flex1 : ''}
                variant={SATypographyVariant.H6}
              >
                Make Collection
              </SATypography>
              <CheckboxV2
                name='privacy'
                id='privacy'
                value={privacy === '1' ? '0' : '1'}
                checked={privacy === '1'}
                onChange={onChangeInput}
                text='Private'
                classNames
                customStyles={
                  !isAdmin
                    ? { flex: 2 }
                    : { minHeight: '41px', justifyContent: 'flex-start', alignItems: 'flex-start' }
                }
              />
            </fieldset>
          </div>
        </div>

        <div data-type='options-div' className={classNames(styles.collectionOptionsDiv)}>
          {isAdmin ? (
            <>
              <fieldset className={classNames(styles.fieldsetBox, styles.titleInputBox)}>
                <SATypography variant={SATypographyVariant.H5}>Saatchi Art</SATypography>
                <div className={styles.checkboxContainer}>
                  {Object.keys(SaatchiLatestCollectionTypes).map((key) => (
                    <CheckboxV2
                      key={key}
                      name='collectionTypeIds'
                      id={`collectionTypeIds${key}`}
                      value={key}
                      checked={collectionTypeIds.includes(key)}
                      onChange={onChangeCheckbox}
                      text={SaatchiLatestCollectionTypes[key]} // Don't allow non-admins to check AA Selection
                      {...(!isAdmin && key === AA_SELECTION_TYPE
                        ? {
                            readOnly: true,
                            disabled: 'disable',
                          }
                        : {})}
                    />
                  ))}
                </div>
                <SATypography
                  color={SATypographyColor.ERROR}
                  data-type='error'
                  variant={SATypographyVariant.X_SMALL}
                >
                  {collectionIdErrors}
                </SATypography>
              </fieldset>

              {collectionTypeIds.includes(AA_SELECTION_TYPE) && (
                <div className={styles.aaForm}>
                  <div className={styles.flexHalfColumn}>
                    <Input
                      error={formErrors.firstName}
                      label='First Name'
                      name='firstName'
                      onChange={onChangeInput}
                      value={firstName}
                    />

                    <Input
                      error={formErrors.email}
                      label='Email'
                      name='email'
                      onChange={onChangeInput}
                      value={email}
                    />
                  </div>
                  <div className={styles.flexHalfColumn}>
                    <Input
                      error={formErrors.lastName}
                      label='Last Name'
                      name='lastName'
                      onChange={onChangeInput}
                      value={lastName}
                    />

                    <Input
                      error={formErrors.zendeskTicketId}
                      label='Zendesk Ticket ID'
                      name='zendeskTicketId'
                      onChange={onChangeInput}
                      value={zendeskTicketId}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <fieldset className={styles.fieldsetBox}>
              <div
                className={classNames(styles.fieldsetBox, styles.titleInputBox, {
                  [styles.titleInputBoxInline]: !isAdmin,
                })}
              >
                <SATypography
                  customDivClassName={!isAdmin ? styles.flex1 : ''}
                  variant={SATypographyVariant.H6}
                >
                  Type
                </SATypography>
                <div
                  className={classNames({
                    [styles.checkboxContainerInline]: !isAdmin,
                  })}
                >
                  <div className={classNames(styles.checkboxContainer)}>
                    {Object.keys(DefaultCollectionTypes).map((key) => (
                      <CheckboxV2
                        key={key}
                        name='collectionTypeIds'
                        id={`collectionTypeIds_${key}`}
                        value={key}
                        checked={collectionTypeIds.includes(key)}
                        onChange={onChangeCheckbox}
                        text={SaatchiLatestCollectionTypes[key]}
                      />
                    ))}
                  </div>
                  <SATypography
                    variant={SATypographyVariant.X_SMALL}
                    color={SATypographyColor.ERROR}
                    data-type='error'
                  >
                    {collectionIdErrors}
                  </SATypography>
                </div>
              </div>
            </fieldset>
          )}

          {isAdmin && (
            <div className={styles.visibilityPrints}>
              {privacy === '0' && (
                <fieldset className={classNames(styles.fieldsetBox, styles.titleInputBox)}>
                  <SATypography variant={SATypographyVariant.H5}>Visibility</SATypography>
                  <div className={styles.radioContainer}>
                    <Radio
                      name='visible'
                      id='visible-1'
                      value='1'
                      checked={visible === '1'}
                      onChange={onChangeInput}
                      text='Browsable'
                    />

                    <Radio
                      name='visible'
                      id='visible-0'
                      value='0'
                      checked={visible === '0'}
                      onChange={onChangeInput}
                      text='Accessible by URL only'
                    />
                  </div>
                </fieldset>
              )}

              <fieldset className={classNames(styles.fieldsetBox, styles.titleInputBox)}>
                <SATypography variant={SATypographyVariant.H5}>Prints View</SATypography>
                <CheckboxV2
                  name='printsView'
                  value='1'
                  checked={printsView === '1'}
                  onChange={onChangeCheckbox}
                  text='Shows print prices by default'
                />
              </fieldset>
            </div>
          )}
        </div>

        <div data-type='buttons-div'>
          <div
            className={classNames(styles.buttonsContainer, {
              [styles.buttonsContainerInline]: !isAdmin,
            })}
          >
            <SAButton
              variant='text'
              customButtonClassName={styles.cancelButton}
              onClick={() => {
                setNoValidate(true)
                closeModal()
              }}
            >
              CANCEL
            </SAButton>

            <SAButton disabled={isSubmitting || null} onClick={onSubmit}>
              {(isSubmitting && 'SAVING...') || 'SUBMIT'}
            </SAButton>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateCollectionV2
