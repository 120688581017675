import React from 'react'
import NavigationLayout from 'layouts/Navigation'
import Layout from './layouts'

const App = (): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <NavigationLayout dataApp='artist-profile'>
      <Layout />
    </NavigationLayout>
  )
}

export default App