import { createSelector } from 'reselect'
import type { User } from 'types/User'
import 'types/User'
import type { Favorite, FavoriteItems, MessagePayload, SelectedItem } from 'types/User/Favorite'
import { getUser } from './helpers'
export const getFavorite = createSelector([getUser], (user: User): Favorite => user.favorite || {})
export const getFavoriteItems = createSelector(
  [getFavorite],
  (favorite: Favorite): FavoriteItems => favorite.items || []
)
export const isFavorited = (artId: number): ((...args: Array<any>) => any) =>
  createSelector(
    [getFavoriteItems],
    (favoriteItems: FavoriteItems): boolean => favoriteItems.includes(artId) || false
  )
export const getFavoriteMessage = createSelector(
  [getFavorite],
  (favorite: Favorite): MessagePayload => favorite.message || {}
)
export const getFavoriteSelected = createSelector(
  [getFavorite],
  (favorite: Favorite): SelectedItem => favorite.selected || null
)