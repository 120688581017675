import React from 'react'
import Animation from 'components/Animations/Animation'
import Portal from './Portal'
const defaultStyles = {
  position: 'fixed',
  top: '0',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  willChange: 'transform, opacity',
  zIndex: '1000',
  overflow: 'auto',
}
const duration = 350
const animation = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
}
type Props = {
  closeModal: (...args: Array<any>) => any
  children: React.ReactElement<React.ComponentProps<any>, any>
  isVisible?: boolean
}
export default ({
  closeModal,
  children,
  isVisible,
}: Props): React.ReactElement<React.ComponentProps<any>, any> => (
  <Portal closeModal={closeModal}>
    <aside role='dialog' aria-modal='true' tabIndex='-1'>
      <Animation
        defaultStyles={defaultStyles}
        duration={duration}
        isVisible={isVisible}
        {...animation}
      >
        {children}
      </Animation>
    </aside>
  </Portal>
)