import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import Share from 'svg/share-icon.svg'
import Pencil from 'svg/pencil-new.svg'
import Facebook from 'svg/facebook-2021.svg'
import Globe from 'svg/globe.svg'
import Instagram from 'svg/instagram.svg'
import Pinterest from 'svg/pinterest.svg'
import Tumblr from 'svg/tumblr.svg'
import Twitter from 'svg/twitter.svg'
import Bulb from 'svg/bulb.svg'
import CaretLeft from 'svg/caret-left.svg'
import CaretRight from 'svg/caret-right.svg'
import Rewind from 'svg/rewind.svg'
import Info from 'svg/info.svg'
import Frame from 'svg/frame.svg'
import AddToCollection from 'svg/add-to-collection.svg'
import Palette from 'svg/palette.svg'
export const MAX_WIDTH_DESKTOP = `770`
export const MAX_HEIGHT_DESKTOP = `426`
export const MAX_WIDTH_MOBILE = `450`
export const MAX_HEIGHT_MOBILE = `450`

// Icons
const IconStyle = (props) => `
  height: 35px;
  width: 35px;
  overflow: visible;
  path {
    stoke: #ffffff;
    stroke-width: 2px;
  }
`

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  font-size: 16px;
  color: #333333;
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
  hr {
    border: none;
    border-top: 1px solid #dcdcdc;
    margin: 0;
    width: 100%;
  }
  [data-section='cover-photo-wrapper'] {
    width: 100%;
    height: 260px;
    background: #f3f3f3;
  }
  [data-section='cover-photo'] {
    &:hover {
      [data-type='edit-hero-section'] {
        [data-type='edit-hero-icon'] {
          display: block;
        }
      }
    }
    [data-type='edit-hero-section'] {
      display: flex;
      flex-direction: row;
      position: absolute;
      z-index: 5;
      justify-content: flex-end;
      top: 30px;
      right: 20px;
      cursor: pointer;
      [data-type='edit-hero-icon'] {
        background-color: #ffffff;
        border-radius: 50%;
        height: 60px;
        padding: 15px 20px;
        width: 60px;
        position: absolute;
        transform: translate(-50%, -50%);
        display: none;
        cursor: pointer;
        margin-top: 30px;
      }
      [data-type='upload-input'] {
        opacity: 0;
        height: 70px;
        width: 120px;
        z-index: 10;
        cursor: pointer;
      }
    }
  }
  [data-section='hero-image-section'] {
    width: 100%;
    height: 260px;
    background: url(https://d3t95n9c6zzriw.cloudfront.net/common/solid-logo.svg) 0 0 no-repeat;
    background-size: 100px 100px;
    background-color: #eeeeee;
    background-position: center;
    [data-type='overlay'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      z-index: 15;
    }
    [data-type='loader'] {
      position: absolute;
      top: 133px;
      left: 50%;
      z-index: 16;
    }
    [data-type='loader-text'] {
      position: absolute;
      top: 158px;
      width: 100%;
      z-index: 16;
      color: #333333;
    }
    [data-type='upload-section'] {
      width: 100%;
      height: 260px;
      background: #f3f3f3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      [data-type='upload-label'] {
        color: #333333;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        text-decoration-line: underline;
        cursor: pointer;
      }
      [data-type='upload-input'] {
        cursor: pointer;
        position: absolute;
        z-index: 5;
        width: 150px;
        height: 90px;
        opacity: 0;
      }
    }
    [data-type='hero-image'] {
      width: 100%;
      img {
        width: 100%;
        height: 260px;
        object-fit: cover;
      }
    }
  }
  [data-type='view-artwork-button'] {
    width: max-content;
    align-self: flex-end;
    margin-top: -72px;
    margin-right: 53px;
    align-items: center;
    white-space: nowrap;
    background: #da3b1f;
    border: none;
    color: #fff;
    font-weight: bold;
    padding: 14px 32px;
    text-decoration: none;
  }
  [data-type='carousel-wrapper'] {
    margin-left: 320px;
    margin-right: 40px;
    @media (max-width: 992px) {
      overflow-x: scroll;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    [data-section='artists-carousel'] {
      [data-type='divider-line'] {
        margin: 54px 0px;
      }
    }
  }
  [data-section='main-content'] {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 0px 0px 30px;
    width: 100%;
    margin-top: 30px;
    [data-section='user-info'] {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 300px;
      width: 100%;
      margin-top: -115px;
      [data-type='divider-line'] {
        margin-top: 24px;
      }
      [data-type='curator-badge'] {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 24px;
        background: #000000;
        color: #ffffff;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        border-radius: 40px;
      }
      [data-type='user-name'] {
        font-weight: bold;
        color: #000000;
        overflow-wrap: anywhere;
        margin-top: 28px;
        margin-bottom: 0;
        text-align: left;
        &[data-style='curator'] {
          margin-top: 11px;
        }
      }
      [data-type='job-title'] {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.02em;
        color: #666666;
        text-align: left;
        margin-bottom: 0;
      }
      [data-type='socials'] {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-top: 24px;
        [data-type='social-icons'] {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
        }
      }
      [data-type='edit-profile-link'] {
        margin-top: 16px;
        color: #333333;
      }
      [data-type='follow-row'] {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        [data-type='follow-button'] {
          height: 45px;
          margin-right: 10px;
        }
      }
      [data-section='share-icons-section'] {
        margin-top: 24px;
        [data-type='share-button'] {
          background: none;
          border: 1px solid #dcdcdc;
          width: 45px;
          height: 45px;
          cursor: pointer;
          padding: 0;
          [data-type='share-text'] {
            margin: 0;
          }
          @media (min-width: 992px) {
            &:hover {
              background: #333333;
              [data-type='share-text'] {
                color: #ffffff;
              }
              svg {
                path {
                  fill: #ffffff;
                }
              }
            }
          }
          &[data-style] {
            background: #333333;
            [data-type='share-text'] {
              color: #ffffff;
            }
            svg {
              path {
                fill: #ffffff;
              }
            }
          }
        }
        [data-type='social-icons'] {
          min-height: 65x;
          width: 65px;
          z-index: 1;
          display: flex;
          position: absolute;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          [data-type='pinterest-link'],
          [data-type='twitter-link'],
          [data-type='email-link'],
          [data-type='facebook-link'] {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 38px;
            width: 45px;
            padding-right: 13px;
            padding-left: 13px;
            background-color: #333333;
          }
        }
      }
      [data-type='user-location-section'] {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 24px;
        [data-type='user-location'] {
          font-size: 16px;
          letter-spacing: -0.02em;
          color: #666666;
          margin: 0;
          text-align: left;
        }
        [data-type='flag-icon'] {
          background: url('https://d3t95n9c6zzriw.cloudfront.net/common/sprite-flag-3.png')
            no-repeat top left;
          background-size: 558px 1009px;
          cursor: default;
          display: block;
          float: left;
          height: 22px;
          margin: 2px 10px 5px 0;
          min-width: 40px;
        }
      }
      [data-type='user-following'] {
        font-size: 16px;
        letter-spacing: -0.02em;
        color: #666666;
        margin-top: 24px;
        text-decoration: none;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        [data-type='count'] {
          font-weight: bold;
          color: #333333;
        }
        &[data-style='curator-following'] {
          flex-direction: row;
          [data-type='following-text'] {
            margin-left: 6px;
          }
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      [data-type='badges'] {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        [data-type='main-title'] {
          color: #000000;
          margin-top: 24px;
          margin-bottom: 16px;
        }
        [data-type='view-more-button'] {
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          text-decoration-line: underline;
          text-transform: uppercase;
          color: #333333;
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;
          margin-top: 9px;
        }
        [data-type='badge'] {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
          &[data-hide='hide'] {
            display: none;
          }
          [data-type='badge-image'] {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
          [data-type='title'] {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: #333333;
            margin: 0;
            text-align: left;
          }
        }
      }
      [data-section='user-about-section'] {
        width: 100%;
        [data-type='nav-tabs'] {
          @media (max-width: 992px) {
            justify-content: flex-start;
          }
          display: flex;
          flex-direction: row;
          gap: 16px;
          list-style: none;
          padding: 0;
          margin-top: 24px;
          margin-bottom: 16px;
          [data-type='nav-tab'] {
            background: none;
            border: none;
            padding: 0;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.02em;
            color: #666666;
            cursor: pointer;
            &[data-index='active'] {
              border-bottom: 2px solid #000000;
              color: #000000;
            }
          }
        }
        [data-type='info'] {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          [data-type='info-text'] {
            color: #333333;
            text-align: left;
            margin-top: 0;
            //overflow-wrap: anywhere;
            word-break: break-word;
          }
          [data-type='view-more-button'] {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            text-decoration-line: underline;
            text-transform: uppercase;
            color: #333333;
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
          }
        }
      }
      [data-section='admin-panel'] {
        width: 100%;
        border: 1px solid #dcdcdc;
        padding: 20px;
        margin-top: 16px;
        h2 {
          margin: 0px;
          font-style: italic;
          font-weight: normal;
          font-size: 22px;
          text-align: left;
        }
        p {
          margin-top: 10px;
          overflow-wrap: anywhere;
          text-align: left;
          span {
            font-weight: bold;
          }
          a {
            text-decoration: none;
            color: #333333;
            &:hover {
              color: #000000;
            }
          }
        }

        @media (max-width: 992px) {
          max-width: 100%;
        }
      }
    }
    [data-section='right-col'] {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0px 40px;
      position: relative;
      justify-content: flex-start;
      flex: 1 1 0%;
      overflow: visible;
    }
    [data-section='artworks-carousel'] {
      width: 100%;
      hr {
        margin-top: 32px;
      }
    }
    [data-section='inside-the-studio'] {
      margin-bottom: 40px;
      [data-type='title-row'] {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      [data-type='edit-button'] {
        background: none;
        border: none;
        cursor: pointer;
      }
      [data-type='main-title'] {
        font-size: 24px;
        line-height: 33px;
        letter-spacing: 0.1em;
        color: #000000;
        text-align: left;
        text-transform: uppercase;
      }
      [data-section='additional-images'] {
        [data-section='sortable'] {
          [data-type='list-item'] {
            [data-type='order'] {
              display: block;
            }
          }
        }
      }
      [data-type='no-results'] {
        height: 286px;
        border: 1px solid #dcdcdc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 32px;
        padding: 0px 30px;
        p {
          margin: 0;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.02em;
          margin-bottom: 35px;
        }
        [data-type='upload-button'] {
          display: flex;
          width: 100%;
          max-width: 327px;
          height: 53px;
          background: #da3b1f;
          font-size: 16px;
          font-weight: bold;
          line-height: 21px;
          color: #ffffff;
          border: none;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
      [data-type='divider-line'] {
        margin: 32px 0px;
      }
      [data-section='image-gallery'] {
        display: flex;
        flex-direction: column;
        align-items: center;
        [data-section='controls'] {
          width: 100%;
          display: flex;
          justify-content: space-between;
          position: absolute;
          left: 0;
          margin-top: 200px;
          z-index: 10;

          svg {
            width: 36px;
            height: 36px;
            cursor: pointer;
          }
          [data-type='next'] {
            margin-right: 35px;
          }
          [data-type='back'] {
            margin-left: 35px;
          }

          @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
            display: none;
          }
        }

        [data-section='dots'] {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px 0;
        }

        [data-type='dot'] {
          border-radius: 100%;
          width: 10px;
          height: 10px;
          margin: 0 10px 0 0;
          padding: 0;
          cursor: pointer;
          background-color: #dcdcdc;
          border: none;

          &:last-child {
            margin-right: 0;
          }

          &[data-style='active'] {
            background: #333333;
          }
        }
      }
    }
    [data-section='reference-line'] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 1px;
      background: none;
      width: 100%;
    }
    [data-section='request-commission'] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f3f3f3;
      width: 100%;
      padding: 40px 20px;
      [data-type='title'] {
        color: #333333;
        margin-bottom: 8px;
      }
      [data-type='sub-title'] {
        color: #333333;
        margin: 0;
      }
      [data-type='req-commission-button'] {
        background: #333333;
        color: #ffffff;
        font-weight: bold;
        height: 53px;
        border: none;
        padding-right: 16px;
        padding-left: 16px;
        cursor: pointer;
        margin-top: 24px;
      }
    }
    [data-type='favorites-section'] {
      margin-top: 32px;
      max-width: 980px;
      min-height: 380px;
      display: block;
      [data-type='main-title'] {
        margin-bottom: 24px;
        color: #000000;
        text-align: left;
        text-transform: uppercase;
      }
      [data-type='view-all'] {
        text-decoration: none;
        display: flex;
        margin-bottom: 32px;
        height: 53px;
        align-items: center;
        justify-content: center;
        max-width: 343px;
        white-space: nowrap;
        background: #da3b1f;
        border: none;
        color: #fff;
        font-weight: bold;
      }
      [data-type='controls'] {
        width: 100%;
        position: relative;
        button {
          cursor: pointer;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 75px;
          background: transparent;
          border: none;
          &:focus {
            outline: none;
          }
          &[data-action='previous'] {
            left: -50px;
          }
          &[data-action='next'] {
            right: -50px;
          }

          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      [data-type='carousel'] {
        display: flex;
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        @media (max-width: 992px) {
          overflow-x: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      [data-section='artwork-info'] {
        font-size: 16px;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        display: flex;
        flex-direction: column;
        [data-type='actions'] {
          display: flex;
          flex-direction: row;
          [data-action='cart'] {
            width: 30px;
            height: 24px;
            padding: 0;
            margin: 0;
            background: transparent;
            path {
              fill: #ffffff;
            }
            &:hover {
              path {
                fill: #77c054;
              }
            }
          }
          [data-type='action']:first-child {
            width: 30px;
            height: 24px;
            padding: 0;
            margin: 0;
            background: transparent;
            svg {
              path {
                fill: #ffffff;
              }
              &[data-favorite='favorite'] {
                path {
                  fill: #ef542c;
                }
              }
            }
            &:hover {
              path {
                stroke: #ef542c;
                fill: #ef542c;
              }
            }
          }
        }

        [data-type='artwork-title'] {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #333333;
          text-decoration: none;
        }
        [data-type='artist-name'] {
          text-decoration: none;
        }
        [data-type='artist-name'],
        [data-type='price'] {
          color: #666666;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      ul {
        display: block;
        width: 100%;
        min-height: 300px;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        list-style: outside none none;
        margin: 0px;
        padding: 0px;
        white-space: nowrap;
        transform: translate3d(0px, 0px, 0px);
        transition: 0.2s ease transform;
        will-change: transform;
        @media (max-width: 992px) {
          min-height: 280px;
        }
      }

      li {
        display: inline-flex;
        justify-content: center;
        align-items: flex-start;
        width: 156px;
        height: 100%;
        vertical-align: top;
        list-style: outside none none;
        margin: 0px;
        padding: 0px;
        overflow: hidden;
        &:not(:last-child) {
          margin-right: 8px;
        }
        [data-type='artwork'] {
          width: 156px;
        }
        img {
          width: 156px;
        }
        p {
          margin: 0;
        }
        @media (max-width: 992px) {
          width: 156px;
          [data-type='artwork'],
          img {
            width: 156px;
          }
          &:not(:last-child) {
            margin-right: 8px;
          }
        }
      }
      @media (max-width: 1350px) {
        max-width: 650px;
      }
    }
    [data-section='collection-section'] {
      hr {
        margin: 54px 0px;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 200px;
      margin-bottom: 54px;
      [data-type='title-section'] {
        margin-bottom: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        [data-type='tool-tip'] {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          [data-type='tool-tip-text'] {
            visibility: hidden;
            width: 180px;
            height: 100px;
            background-color: #333333;
            opacity: 0.9;
            color: #fff;
            text-align: center;
            padding: 16px;
            position: absolute;
            z-index: 5;
            font-size: 12px;
            line-height: 16px;
            margin-top: 29px;
          }
          &:hover {
            cursor: pointer;
            [data-type='tool-tip-text'] {
              visibility: visible;
            }
          }
        }
      }
      [data-type='main-title'] {
        color: #000000;
      }
      [data-type='view-all'] {
        text-decoration: none;
        display: flex;
        margin: 54px auto 0px auto;
        height: 53px;
        align-items: center;
        justify-content: center;
        max-width: 343px;
        white-space: nowrap;
        background: #da3b1f;
        border: none;
        color: #fff;
        font-weight: bold;
        width: 100%;
        &[data-style='curator'] {
          margin-left: 0;
          margin-right: 0;
        }
      }
      [data-type='collection-tiles'] {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        [data-type='tile'] {
          margin-right: 8px;
          margin-bottom: 8px;
          position: relative;
          max-width: 156px;
          width: 100%;
          [data-type='image'] {
            display: block;
            width: 156px;
            height: 156px;
          }
          [data-type='title'] {
            margin-top: 60px;
            color: #ffffff;
            text-align: center;
            display: block;
            padding: 0px 10px;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            overflow-wrap: anywhere;
          }
          [data-type='artwork-count'] {
            color: #ffffff;
            text-align: center;
            display: block;
            padding: 0px 10px;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            margin-top: 5px;
          }
          [data-type='overlay'] {
            position: absolute;
            bottom: 0;
            background: rgba(0, 0, 0, 0.4);
            width: 100%;
            height: 100%;
            text-align: center;
          }
        }
      }
    }
    [data-section='art-advisory'] {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      height: 225px;
      [data-type='image-section'] {
        display: flex;
        flex-direction: row;
      }
      [data-type='info-section'] {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 50px;
        padding-right: 20px;
        width: 100%;
        background: #f3f3f3;
        text-align: left;
        [data-type='title'] {
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 28px;
          color: #333333;
          margin-top: 32px;
          margin-bottom: 0;
        }
        [data-type='sub-title'] {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.02em;
          color: #333333;
          margin-top: 22px;
          margin-bottom: 0;
        }
        [data-type='get-started-button'] {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 247px;
          height: 53px;
          background: #333333;
          font-size: 18px;
          font-weight: bold;
          text-decoration: none;
          color: #ffffff;
          margin-top: 16px;
          padding: 16px 0px;
          cursor: pointer;
        }
      }
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 125px;
        margin-bottom: 40px;
        [data-type='image-section'] {
          order: 2;
          width: 100%;
          img {
            width: 50%;
            object-fit: cover;
          }
        }
        [data-type='info-section'] {
          order: 1;
          width: 100%;
          padding-bottom: 22px;
          padding-left: 20px;
          padding-right: 20px;
          [data-type='get-started-button'] {
            width: 100%;
            max-width: 247px;
          }
        }
      }
    }
    [data-section='artworks'] {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin-bottom: 54px;
      [data-style='artist-artwork'] {
        figure {
          align-items: flex-start;
          [data-type='artwork-image'] {
            a {
              display: flex;
            }
          }
        }
        [data-type='artwork-info-container'] {
          display: flex;
          flex-direction: column;
          gap: 4px;
          margin-top: 8px;
        }
        [data-type='actions'] {
          display: flex;
          flex-direction: row;
          width: 320px;
          justify-content: space-between;
          [data-type='sold-badge'] {
            background-color: #da3b1f;
            color: #ffffff;
            font-size: 11px;
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 50%;
            height: 37px;
            width: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            line-height: 18px;
            white-space: nowrap;
          }
          [data-type='artwork-actions'] {
            display: flex;
            flex-direction: row;
          }
          [data-action='collection'] {
            border: 1px solid #333333;
            margin-left: 12px;
            width: 25px;
            height: 25px;
            margin-top: -1px;
            svg {
              width: 12px;
              height: 15px;
              path {
                stroke: #333;
                stroke-width: 0.2px;
              }
            }
          }
          [data-action='cart'] {
            width: 30px;
            height: 24px;
            padding: 0;
            margin: 0;
            margin-left: 3px;
            background: transparent;
            path {
              fill: #ffffff;
            }
            &:hover {
              path {
                fill: #77c054;
              }
            }
          }
          [data-type='action']:first-child {
            width: 30px;
            height: 24px;
            padding: 0;
            margin: 0;
            background: transparent;
            svg {
              path {
                fill: #ffffff;
              }
              &[data-favorite='favorite'] {
                path {
                  fill: #ef542c;
                }
              }
            }
            &:hover {
              path {
                stroke: #ef542c;
                fill: #ef542c;
              }
            }
          }
        }
        [data-type='artwork-info'] {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 16px;
          width: 320px;
          @media (max-width: 992px) {
            gap: 6px;
          }
          [data-type='artwork-title'] {
            color: #1e1e1e;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0;
            max-width: 65%;
            text-decoration: none;
          }
          [data-type='price'] {
            color: #1e1e1e;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0;
          }
        }
        [data-type='info-row'] {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 16px;
          width: 320px;
        }
        [data-type='title-dim'] {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0px;
        }
        [data-type='country-print-price'] {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 16px;
        }
      }
      [data-type='divider-line'] {
        margin-top: 40px;
      }
      [data-type='no-results'] {
        height: 286px;
        border: 1px solid #dcdcdc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 32px;
        p {
          margin: 0;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.02em;
        }
        [data-type='browse-link'] {
          display: flex;
          margin-top: 24px;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          text-decoration-line: underline;
          text-transform: uppercase;
          color: #333333;
        }
      }
      [data-type='main-title'] {
        margin: 20px 0px 32px 0px;
        color: #000000;
      }
      [data-type='view-all'] {
        text-decoration: none;
        display: flex;
        margin-top: 32px;
        margin-bottom: 32px;
        height: 53px;
        align-items: center;
        justify-content: center;
        max-width: 343px;
        white-space: nowrap;
        background: #da3b1f;
        border: none;
        color: #fff;
        font-weight: bold;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  @media (max-width: 768px) {
    [data-section='cover-photo-wrapper'] {
      width: 100%;
      height: 130px;
      background: #f3f3f3;
    }
    [data-section='cover-photo'] {
      width: 100%;
      [data-type='edit-hero-section'] {
        top: 10px;
        right: 0px;
      }
    }
    [data-section='hero-image-section'] {
      height: 130px;
      [data-type='upload-section'] {
        width: 100%;
        height: 130px;
        background: #f3f3f3;
        justify-content: flex-start;
        svg {
          width: 40px;
          height: 30px;
          margin-bottom: 0px;
          margin-top: 10px;
        }
      }
      [data-type='hero-image'] {
        width: 100%;
        img {
          width: 100%;
          height: 130px;
          object-fit: cover;
        }
      }
    }
  }
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    [data-type='view-artwork-button'] {
      margin-right: 16px;
      padding: 8px;
      margin-top: -50px;
      z-index: 5;
    }
    [data-section='cover-photo-wrapper'] {
      width: 100%;
    }
    [data-section='cover-photo'] {
      width: 100%;
    }
    [data-section='main-content'] {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      padding: 0 16px;
      margin-top: 18px;
      [data-section='right-col'] {
        padding: 0;
      }
      [data-section='user-info'] {
        display: flex;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-top: -57px;
        [data-type='user-name-container'] {
          @media (max-width: 992px) {
            width: 50%;
          }
        }
        &[data-style='curator'] {
          margin-top: -71px;
        }
        [data-style='follow-border'] {
          display: none;
        }
        [data-type='job-title'] {
          order: 3;
        }
        [data-type='curator-badge'] {
          margin: 8px 0px;
          order: 2;
        }
        [data-type='user-name'] {
          order: 2;
        }
        [data-type='user-name'],
        [data-type='user-location-section'] {
          order: 3;
          margin-top: 16px;
          justify-content: center;
          align-items: center;
          [data-type='user-location'] {
            margin-top: -2px;
          }
        }
        [data-type='divider-line'] {
          order: 5;
        }
        [data-type='edit-profile-link'] {
          order: 4;
        }
        [data-type='follow-row'] {
          order: 1;
          margin-top: -60px;
          justify-content: flex-end;
          @media (max-width: 500px) {
            margin-top: 0px;
            justify-content: flex-start;
          }
          [data-type='user-following'] {
            margin-right: 10px;
            [data-type='following-text'] {
              margin-right: 16px;
            }
          }
        }
        [data-type='badges'] {
          order: 6;
        }
        [data-style='badges-border'] {
          order: 7;
        }
        [data-section='user-about-section'] {
          order: 8;
          [data-type='nav-tabs'] {
            [data-type='nav-tab'] {
              margin-right: 30px;
            }
          }
        }
        [data-section='admin-panel'] {
          order: 9;
        }
      }
      [data-section='collection-section'] {
        [data-type='title-section'] {
          [data-type='tool-tip'] {
            display: none;
          }
        }
        [data-type='collection-tiles'] {
          overflow-x: auto;
          flex-wrap: nowrap;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      [data-section='artworks'] {
        [data-style='artist-artwork'] {
          figure {
            align-items: center;
          }
          [data-type='actions'] {
            width: 140px;
            [data-type='sold-badge'] {
              margin-top: -50px;
              z-index: 5;
              margin-right: 5px;
            }
          }
          [data-type='artwork-info'] {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 140px;
            [data-type='artwork-title'] {
              width: 100%;
              max-width: 100%;
              text-align: left;
            }
            [data-type='price'] {
              width: 100%;
              text-align: left;
            }
          }
          [data-type='info-row'] {
            flex-direction: column;
            width: 140px;
          }
          [data-type='title-dim'] {
            display: none;
          }
          [data-type='country-print-price'] {
            display: none;
          }
        }
      }
    }
  }
`
export const ShareIcon = styled(Share)`
  fill: #fff;
  height: 25px;
  display: block;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
`
export const BulbIcon = styled(Bulb)`
  width: 62px;
  height: 56px;
  margin-bottom: 16px;
`
export const PaletteIcon = styled(Palette)`
  width: 65px;
  height: 65px;
`
export const FrameIcon = styled(Frame)`
  width: 62px;
  height: 47px;
  margin-bottom: 22px;
`
export const AddToCollectionIcon = styled(AddToCollection)`
  width: 21px;
  height: 19px;
  margin-left: 11px;
  cursor: pointer;
  margin-top: 2px;
  &:hover {
    fill: #333333;
    path {
      stroke: #ffffff;
    }
  }
  @media (max-width: 992px) {
    display: none;
  }
`
export const PencilIcon = styled(Pencil)`
  position: absolute;
  left: 20px;
  top: 20px;
  width: 19px;
  height: 19px;
`
export const EditIcon = styled(Pencil)`
  width: 19px;
  height: 19px;
`
export const FacebookIcon = styled(Facebook)`
  height: 18px;
  width: 18px;
  fill: #333333;
  path {
    fill: #333333;
  }
  margin-right: 14px;
`
export const TwitterIcon = styled(Twitter)`
  height: 18px;
  width: 18px;
  fill: #333333;
  path {
    fill: #333333;
  }
  margin-right: 14px;
`
export const InstagramIcon = styled(Instagram)`
  height: 18px;
  width: 18px;
  fill: #333333;
  path {
    fill: #333333;
  }
  margin-right: 14px;
`
export const TumblrIcon = styled(Tumblr)`
  height: 18px;
  width: 18px;
  fill: #333333;
  path {
    fill: #333333;
  }
  margin-right: 14px;
`
export const GlobeIcon = styled(Globe)`
  height: 18px;
  width: 18px;
  fill: #333333;
  path {
    fill: #333333;
  }
  margin-right: 14px;
`
export const PinterestIcon = styled(Pinterest)`
  height: 18px;
  width: 18px;
  fill: #333333;
  path {
    fill: #333333;
  }
  margin-right: 14px;
`
export const InfoIcon = styled(Info)`
  height: 18px;
  width: 18px;
  margin-left: 8px;
  margin-top: -2px;
`
export const ArtworkImage = styled.div`
  position: relative;
  display: inline-block;
  picture {
    img {
      height: 100%;
      width: 100%;
      display: block;
      pointer-events: none;
      background: #dcdcdc;
      visibility: inherit;
    }
  }
  [data-type='reserved-label'] {
    background-color: #da3b1f;
    color: #ffffff;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    width: 64px;
    height: 16px;
    position: absolute;
    top: 0px;
    left: 0px;
    text-transform: uppercase;
    font-weight: bold;
  }
`
export const NextIcon = styled(CaretRight)`
  ${(props) => IconStyle(props)};
`
export const PrevIcon = styled(CaretLeft)`
  ${(props) => IconStyle(props)};
`
export const RewindIcon = styled(Rewind)`
  width: 16px;
  height: 16px;
  overflow: visible;
`
export const Section = styled.section`
  height: 350px;
  text-align: left;
  [data-type='main-title'] {
    margin-bottom: 16px;
  }
  &[data-section='you-may-also-like'],
  &[data-section='similar-artists'] {
    margin-bottom: 25px;
    margin-right: 30px;
    @media (max-width: 992px) {
      margin-right: 0px;
    }
  }
  [data-type='controls'] {
    width: 100%;
    position: relative;
    button {
      cursor: pointer;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 75px;
      background: transparent;
      border: none;
      &:focus {
        outline: none;
      }
      &[data-action='previous'] {
        left: -50px;
      }
      &[data-action='next'] {
        right: -50px;
      }

      &[data-action='restart'] {
        right: 0;
        top: -30px;
        span {
          font-size: 12px;
          font-weight: bold;
          color: #333333;
          margin: 2px 0 0 5px;
        }
      }

      @media (max-width: 992px) {
        display: none;
      }
    }
  }

  &[data-section='similar-artists'] {
    [data-type='controls'] button {
      &[data-action='previous'],
      &[data-action='next'] {
        top: 110px;
      }
    }
  }

  [data-type='carousel'] {
    display: flex;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    @media (max-width: 992px) {
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  [data-section='artwork-info'] {
    font-size: 16px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    [data-type='actions'] {
      display: flex;
      flex-direction: row;
      [data-action='cart'] {
        margin-left: 8px;
        height: 25px;
        width: 24px;
        path {
          fill: #ffffff;
        }
        &:hover {
          path {
            fill: #77c054;
          }
        }
      }
      [data-type='action']:first-child {
        width: 24px;
        height: 24px;
        svg {
          path {
            fill: #ffffff;
          }
          &[data-favorite='favorite'] {
            path {
              fill: #ef542c;
            }
          }
        }
        &:hover {
          path {
            stroke: #ef542c;
            fill: #ef542c;
          }
        }
      }
    }

    [data-type='artwork-title'] {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #333333;
      margin: 0;
    }
    [data-type='artist-name'] {
      text-decoration: none;
    }
    [data-type='artist-name'],
    [data-type='price'] {
      color: #666666;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }
  }

  ul {
    display: block;
    width: 100%;
    min-height: 300px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    list-style: outside none none;
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    transform: translate3d(0px, 0px, 0px);
    transition: 0.2s ease transform;
    will-change: transform;
    @media (max-width: 992px) {
      min-height: 270px;
    }
  }

  li {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    width: 176px;
    height: 100%;
    vertical-align: top;
    list-style: outside none none;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    &:not(:last-child) {
      margin-right: 45px;
    }
    &[data-type='artist'] {
      width: auto;
    }
    [data-type='artwork'] {
      width: 176px;
    }
    img {
      width: 176px;
    }
    @media (max-width: 992px) {
      width: 160px;
      [data-type='artwork'],
      img {
        width: 160px;
        height: 160px;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  a[data-type='see-more'] {
    width: 176px;
    height: 176px;
    background: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    color: #333333;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    @media (max-width: 992px) {
      width: 160px;
      height: 160px;
    }
  }
`
export const Gallery = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: ${MAX_WIDTH_DESKTOP}px;
  min-height: ${MAX_HEIGHT_DESKTOP}px;
  overflow: hidden;

  [data-section='images'] {
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
    white-space: nowrap;
    transform: translateX(0px);
    transition: transform 0.2s ease 0s;
  }

  [data-type='image'] {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*padding: 5px 10px 0 10px;*/
    user-select: none;
    pointer-events: none;
    min-width: ${MAX_WIDTH_DESKTOP}px;
    min-height: ${MAX_HEIGHT_DESKTOP}px;
    img {
      width: auto;
      height: auto;
      max-width: ${MAX_WIDTH_DESKTOP}px;
      max-height: ${MAX_HEIGHT_DESKTOP}px;
    }
    p {
      max-width: ${MAX_WIDTH_DESKTOP}px;
      white-space: initial;
      overflow-wrap: anywhere;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    max-width: ${MAX_WIDTH_MOBILE}px;
    min-height: ${MAX_HEIGHT_MOBILE}px;

    [data-type='image'] {
      min-width: ${MAX_WIDTH_MOBILE}px;
      min-height: ${MAX_HEIGHT_MOBILE}px;
      img {
        max-width: ${MAX_WIDTH_MOBILE}px;
        max-height: ${MAX_HEIGHT_MOBILE}px;
      }
      p {
        width: 100%;
        white-space: initial;
        overflow-wrap: anywhere;
      }
    }
  }

  @media (max-width: ${MAX_WIDTH_MOBILE}px) {
    max-width: calc(100vw - 50px);
    min-height: calc(100vw - 50px);

    [data-type='image'] {
      min-width: calc(100vw - 50px);
      min-height: calc(100vw - 50px);
      img {
        max-width: calc(100vw - 50px);
        max-height: calc(100vw - 50px);
      }
    }
  }

  @media (max-width: 375px) {
    max-width: calc(100vw - 100px);
    min-height: calc(100vw - 100px);

    [data-type='image'] {
      min-width: calc(100vw - 100px);
      min-height: calc(100vw - 100px);
      img {
        max-width: calc(100vw - 100px);
        max-height: calc(100vw - 100px);
      }
    }
  }
`
