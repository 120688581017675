import React from 'react'
import { Facebook, Mail, Pinterest, Twitter } from 'components/SocialActionIcons'
type Props = {
  location: string
  fullName: string
}

const Component = ({ fullName, location }: Props): React$ElementRef<any> => {
  const text = `View ${fullName} on Saatchi Art @SaatchiArt`
  const twitterText = `View ${fullName} on Saatchi Art @SaatchiArt`
  const pinterestText = `View ${fullName} on Saatchi Art @SaatchiArt`
  const media = ''
  const subject = `Check out this artist on Saatchi Art!`
  const body = `I thought you might be interested in ${fullName} from Saatchi Art\n${location}`
  return (
    <div data-type='social-icons'>
      <Facebook url={location} text={text} />
      <Twitter url={location} text={twitterText} />
      <Pinterest url={location} text={pinterestText} media={media} />
      <Mail subject={subject} body={body} />
    </div>
  )
}

export default Component