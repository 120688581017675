import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getUserID } from 'selectors/user/session'
import { getCollections, getAccountUserID, getAccountType } from '../selectors'
const mapStateToProps = createStructuredSelector({
  collections: getCollections,
  userID: getAccountUserID,
  sessionUserID: getUserID,
  accountType: getAccountType,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  collections: Array<Record<string, any>>
  userID: number
  sessionUserID: number
  accountType: string
}
export default connect(mapStateToProps, mapDispatchToProps)