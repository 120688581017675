//@ts-nocheck

import { useEffect, useReducer } from 'react'

type startTimerProps = {
  callBack: (...args: Array<any>) => any
  timerDuration: number
}
type Props = {
  enableTimer?: boolean
  items: number
  timerDuration?: number
}
type State = {
  isTimerEnabled: boolean
  items: number
  position: number
  timerDuration: number
}
type HookProps = {
  enableTimer: (...args: Array<any>) => any
  isTimerEnabled: boolean
  nextPosition: (...args: Array<any>) => any
  position: number
  prevPosition: (...args: Array<any>) => any
  setItems: (...args: Array<any>) => any
  setPosition: (...args: Array<any>) => any
}
// Helpers
let timer

const resetTimer = (): void => {
  clearTimeout(timer)
  timer = null
}

const startTimer = ({ callBack, timerDuration }: startTimerProps): void => {
  resetTimer()
  timer = setTimeout(() => {
    callBack()
  }, timerDuration)
}

const initialState: State = {
  isTimerEnabled: false,
  items: 0,
  position: 0,
  timerDuration: 8000, // 8 seconds
}

const reducer = (state: Record<string, any>, data: Record<string, any>): State => {
  if (data.position) {
    // Validate Position
    const { items } = state

    if (data.position > items - 1) {
      data.position = 0
    } else if (data.position < 0) {
      data.position = items - 1
    }
  }

  return { ...state, ...data }
}

const CarouselTimerHook = (props: Props): HookProps => {
  const [carouselState, updateCarouselState] = useReducer(reducer, {
    ...initialState,
    ...(props.items
      ? {
          items: props.items,
        }
      : {}),
    ...(props.timerDuration
      ? {
          timerDuration: props.timerDuration,
        }
      : {}),
    isTimerEnabled: props.enableTimer,
  })
  const { position, isTimerEnabled, timerDuration } = carouselState
  // Every Time we UPDATE State, check if we need to restart TIMER
  useEffect(() => {
    resetTimer() // Always Clear TIMER

    if (isTimerEnabled) {
      startTimer({
        callBack: () => {
          updateCarouselState({
            position: position + 1,
          })
        },
        timerDuration,
      })
    }
  })

  const prevPosition = () => {
    updateCarouselState({
      position: position - 1,
    })
  }

  const nextPosition = () => {
    updateCarouselState({
      position: position + 1,
    })
  }

  const setPosition = (newPosition: number) => {
    updateCarouselState({
      position: newPosition,
    })
  }

  const setItems = (newItems: number) => {
    updateCarouselState({
      items: newItems || 0,
    })
  }

  const enableTimer = (status: boolean) => {
    updateCarouselState({
      isTimerEnabled: status,
    })
  }

  return {
    enableTimer,
    isTimerEnabled,
    nextPosition,
    position,
    prevPosition,
    setItems,
    setPosition,
  }
}

export default CarouselTimerHook
