import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const MobileSwipe = styled.div`
  touch-action: pan-y;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`