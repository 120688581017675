import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getAccountType } from '../selectors'
const mapStateToProps = createStructuredSelector({
  accountType: getAccountType,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  accountType: string
}
export default connect(mapStateToProps, mapDispatchToProps)