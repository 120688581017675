import CreateCollectionsContent from 'components/Collection/V2/CreateCollectionsContent'
import { useSAModal } from 'components/SA/Modal'
import React, { forwardRef, useImperativeHandle } from 'react'
import styles from './Modal.module.scss'
import { addStoreLocaleByCookie } from 'components/GeoLocation/helpers'

export type CreateCollectionsModalProps = {
  onSuccess?: (...args: Array<any>) => any
}

export type CreateCollectionsModalApi = {
  handleOpenModal: () => void
}

export type GetCreatedCollectionUrlParams = {
  payload: Record<string, any>
  formData: Record<string, any>
  userId: string | number
}

export const getCreatedCollectionUrl = ({
  payload,
  formData,
  userId,
}: GetCreatedCollectionUrlParams) => {
  const { data, collection_id } = payload
  const { title } = formData
  const isAASelection = !!collection_id
  const transformedTitle = (title ?? '').replace(/\s+/g, '-')
  const newCollectionPath = `${
    isAASelection ? 'aa-collection/' : ''
  }art-collection/${transformedTitle}/${userId}/${
    isAASelection ? collection_id : data['id_user_collection']
  }/view`

  return addStoreLocaleByCookie(newCollectionPath)
}

const CreateCollectionsModal = forwardRef<
  CreateCollectionsModalApi,
  React.PropsWithChildren<CreateCollectionsModalProps>
>(({ onSuccess }, ref) => {
  const { SAModal, handleOpenModal, handleCloseModal } = useSAModal()

  useImperativeHandle(ref, () => ({
    handleOpenModal,
  }))

  return (
    <SAModal contentStylesClassname={styles.modalContent}>
      <CreateCollectionsContent closeModal={handleCloseModal} onSuccess={onSuccess} />
    </SAModal>
  )
})

export default CreateCollectionsModal
