const { APP_ENV } = process.env
const DOMAIN =
  APP_ENV === 'local' || APP_ENV === 'qa'
    ? 'https://www.qa.saatchiart.com'
    : 'https://www.saatchiart.com'

export const numberWithCommas = (value: string): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
// function to replace long string with ... based on string length value
export const displayEllipticalString = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text
  }

  return `${text.slice(0, maxLength)}...`
}
export const updateDataLayer = (gtmEventData: Record<string, any>): void => {
  window && window.dataLayer && window.dataLayer.push({ ...gtmEventData })
}

export const updateImagePathBasedOnHostName = (urlString) => {
  try {
    const url = new URL(urlString)
    // qa user profiles have different cloudfront distribution names eg: https://d3p16shm4e5pue.cloudfront.net/*
    // these now point to https://www.qa.saatchiart.com/saatchi-dev-s3/*
    //some user profiles are snapshot from prod, so they all have prod cloudfront urls
    // eg: https://d38we5ntdyxyje.cloudfront.net/*
    // these now point to https://www.saatchiart.com/saatchi-s3/*
    // we need to do this check to construct the correct URL
    const prodCloudFrontUrl = 'd38we5ntdyxyje.cloudfront.net'
    if (url.hostname === prodCloudFrontUrl) {
      return `${DOMAIN}/saatchi-s3` + url.pathname + url.search + url.hash
    }
    return `${DOMAIN}/saatchi-dev-s3` + url.pathname + url.search + url.hash
  } catch (error) {
    // If the URL is not absolute, return the input as it is
    return urlString
  }
}
