"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.privatePageSetup = void 0;
const ssr_1 = require("actions/ssr");
// SSR Routes should include `allClientDataMiddleware` => /server/apis/easel/client/middleware
// USER: abTest, cart, client, cookie, isMobileTraffic, locale, session
// SHARED: client, isMobileTraffic, locale,
// Abstracting for simple setup of common functions used for all apps
const privatePageSetup = (store, req) => {
    if (store && req) {
        const { allClientData } = req;
        store.dispatch(ssr_1.Actions.SSR_COMPLETE(allClientData));
    }
};
exports.privatePageSetup = privatePageSetup;
