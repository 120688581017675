import React, { useEffect } from 'react'
import type { ConnectorProps as ModalProps } from 'containers/Modal'
import ModalConnector from 'containers/Modal'
import Content from './Content'
import { Standard, FullScreen } from './Layouts'
let timer

const timerCleanup = () => {
  clearTimeout(timer)
  timer = null
}

const animationDelay = (callBack: (...args: Array<any>) => any, duration) => {
  timerCleanup()
  timer = setTimeout(() => {
    callBack() // We remove ALL Redux state modal info
  }, duration)
}

const getLayout = (layout: string): Record<string, any> => {
  switch (layout) {
    case 'fullscreen':
      return FullScreen

    case 'modal':
    default:
      return Standard
  }
}

type Props = ModalProps & {
  children: React.ReactElement<React.ComponentProps<any>, any>
  layout: string
  name: string
  // Used in SELECTORS to check REDUX modal state
  decayTime: number
  disableClose?: boolean
}
export default ModalConnector((props: Props): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const {
    children,
    closeModal,
    isModalAvailable,
    isVisible,
    layout,
    decayTime,
    resetModal,
    disableClose,
  } = props

  // We check Redux state to see if NAME matches currrent modal
  if (!isModalAvailable) {
    return null
  }

  const duration = decayTime || 1000
  // Wait for animations to finish, before we clear/remove modal
  useEffect(() => {
    if (!isVisible) {
      animationDelay(resetModal, duration)
    }
  }, [isVisible])
  const Layout = getLayout(layout)
  return (
    <Content closeModal={closeModal} isVisible={isVisible}>
      {React.createElement(
        Layout,
        {
          closeModal,
          disableClose,
        },
        children
      )}
    </Content>
  )
})