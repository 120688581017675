import React from 'react'
import SALink from 'components/SALink'

const Component = (): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <div data-section='art-advisory'>
      <SALink href='/artadvisory' title='Art Advisory' data-type='image-section'>
        <img
          width='140'
          height='225'
          itemProp='image'
          className='lazyload'
          loading='lazy'
          data-src='//d3t95n9c6zzriw.cloudfront.net/art-advisory/aa-client-left-side.png'
        />
        <img
          width='222'
          height='225'
          itemProp='image'
          className='lazyload'
          loading='lazy'
          data-src='https://d3t95n9c6zzriw.cloudfront.net/art-advisory/aa-client-right-side.png'
        />
      </SALink>
      <div data-type='info-section'>
        <p data-type='title'> Free Art Advisory </p>
        <p data-type='sub-title'>
          Request guidance from a curator to find artworks handpicked for you to purchase.
        </p>
        <SALink data-type='get-started-button' href='/artadvisory' title='Art Advisory'>
          Get Started
        </SALink>
      </div>
    </div>
  )
}

export default Component