import React, { useState } from 'react'
import { replaceBadges } from 'lib/badges'
import { updateDataLayer } from '../../helpers'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = {
  badges: Array<Record<string, any>>
}
const MAX_SHOW_ITEMS = 2

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { badges } = props
  const [showContent, setShowContent] = useState(false)

  if (!badges.length) {
    return null
  }

  return (
    <div data-type='badges'>
      <SATypography data-type='main-title' variant={SATypographyVariant.H6}>
        Recognition
      </SATypography>
      {replaceBadges(badges).map(({ image, title }, key) => {
        return (
          <div
            data-hide={!showContent && key >= MAX_SHOW_ITEMS ? 'hide' : 'show'}
            data-type='badge'
            key={title + key}
          >
            <img
              data-type='badge-image'
              className='lazyload'
              data-src={image}
              alt={`link - ${title}`}
            />
            <SATypography data-type='title' variant={SATypographyVariant.X_SMALL}>
              {title}
            </SATypography>
          </div>
        )
      })}
      {badges.length && badges.length > MAX_SHOW_ITEMS && (
        <button
          type='button'
          data-type='view-more-button'
          onClick={() => {
            !showContent &&
              updateDataLayer({
                event: 'view more',
                label: 'badges',
              })
            setShowContent(!showContent)
          }}
        >
          {!showContent ? 'View more' : 'View less'}
        </button>
      )}
    </div>
  )
}

export default Component
