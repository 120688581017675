export const CollectionFormDefaults = {
  collectionTypeIds: [],
  description: '',
  header: '',
  privacy: '0',
  subtitle: '',
  title: '',
  visible: '1',
  printsView: '0',
  // AA Form Elements - Type 17
  firstName: '',
  lastName: '',
  email: '',
  zendeskTicketId: '',
}
export const AA_SELECTION_TYPE = '17'