import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as FlashMessageActions } from 'actions/shared/flashMessage'
import { getUserID, getIsAdmin, isSessionSet } from 'selectors/user/session'
import { getHeroImages, getAccountUserID } from '../selectors'
const mapStateToProps = createStructuredSelector({
  heroImages: getHeroImages,
  accountUserID: getAccountUserID,
  sessionUserID: getUserID,
  isAdmin: getIsAdmin,
  isSessionSet,
})

const mapDispatchToProps = (dispatch) => ({
  addMessage: (message, type) => {
    dispatch(
      FlashMessageActions.ADD({
        messages: [message],
        type: type || 'error',
      })
    )
  },
})

export type ConnectorProps = {
  heroImages: Record<string, any>
  addMessage: (...args: Array<any>) => any
  accountUserID: number
  sessionUserID: number
  isAdmin: boolean
  isSessionSet: boolean
}
export default connect(mapStateToProps, mapDispatchToProps)