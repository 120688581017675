"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProductCategoryPagination = exports.getProductCategoryPluralized = exports.getProductCategoryUrl = void 0;
const getProductCategoryUrl = (category) => {
    switch (category) {
        case 'Drawings':
            return 'Drawing';
        case 'Paintings':
            return 'Painting';
        default:
            return category;
    }
};
exports.getProductCategoryUrl = getProductCategoryUrl;
const PLURALIZED_CATEGORIES = {
    'Mixed Media': 'Artworks',
    Sculpture: 'Sculptures',
    Collage: 'Collages',
    Installation: 'Installations',
    Photography: 'Photographs',
    Digital: 'Digital',
    Printmaking: 'Prints',
    All: 'Artworks',
    Collection: 'Collections',
    Artwork: 'Artworks',
    Artist: 'Artists',
};
const getProductCategoryPluralized = (category) => {
    return PLURALIZED_CATEGORIES[category] || category;
};
exports.getProductCategoryPluralized = getProductCategoryPluralized;
const getProductCategoryPagination = (category) => {
    return (0, exports.getProductCategoryPluralized)(category).toLowerCase();
};
exports.getProductCategoryPagination = getProductCategoryPagination;
