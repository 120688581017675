import React from 'react'
import { PaletteIcon } from '../styles'
type Props = {
  setShowUploader: (...args: Array<any>) => any
  showUploader: boolean
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { showUploader, setShowUploader } = props
  return (
    <div data-type='no-results'>
      <PaletteIcon />
      <p>
        Uploading your studio images gives collectors a better sense of you as an artist. Consider
        sharing images such as you in action in your studio, close-ups of materials,
        work-in-progress, and more.
      </p>
      <button
        type='button'
        title='Upload Studio Images'
        data-type='upload-button'
        onClick={() => setShowUploader(!showUploader)}
      >
        Upload Studio Images
      </button>
    </div>
  )
}

export default Component