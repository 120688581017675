import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as FlashMessageActions } from 'actions/shared/flashMessage'
import { Actions as CollectionActions } from 'actions/user/collection'
import { getCollections, getCollectionsLoaded } from 'selectors/user/collection'
import { getIsAdmin, getUserID } from 'selectors/user/session'
import { getFavoriteSelected } from 'selectors/user/favorite'

const mapStateToProps = createStructuredSelector({
  collections: getCollections,
  collectionsLoaded: getCollectionsLoaded,
  isAdmin: getIsAdmin,
  userId: getUserID,
  selectedFavorite: getFavoriteSelected,
})

const mapDispatchToProps = (dispatch) => ({
  addCollection: (payload: Record<string, any>) =>
    dispatch(CollectionActions.UPDATE_COLLECTIONS(payload)),
  addMessage: (payload: Record<string, any>) => dispatch(FlashMessageActions.ADD(payload)),
  setCollections: (payload: Record<string, any>) =>
    dispatch(CollectionActions.SET_COLLECTIONS(payload)),
})

export type ConnectorProps = {
  addCollection: (...args: Array<any>) => any
  addMessage: (...args: Array<any>) => any
  collections: Array<Record<string, any>>
  collectionsLoaded: boolean
  isAdmin: boolean
  setCollections: (...args: Array<any>) => any
  userId: number
  selectedFavorite: number
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
