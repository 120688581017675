import { createSelector } from 'reselect'
import { getPageData, getPageParams } from 'selectors/page'
import { getUserID } from 'selectors/user/session'
import { CURATOR } from 'lib/user/constants'
export { getIsArtist, isSessionSet } from 'selectors/user/session'
export { getIsMobileTraffic } from 'selectors/shared/mobileTraffic'
export const getPageParamUserId = createSelector(
  [getPageParams],
  (pageParams): string => pageParams.userId || ''
)
export const getAccountData = createSelector(
  [getPageData],
  (pageData): Record<string, any> => pageData.accountData || {}
)
export const getAccountType = createSelector(
  [getPageData],
  (pageData): Record<string, any> => pageData.accountType || 'Artist'
)
export const getFullName = createSelector(
  [getAccountData],
  ({ firstName, lastName }): string => `${firstName} ${lastName}`
)
export const getCountry = createSelector(
  [getAccountData],
  (accountData): string => accountData.country || ''
)
export const getUserName = createSelector(
  [getAccountData],
  (accountData): string => accountData.userName || ''
)
export const getAvatar = createSelector(
  [getAccountData],
  (accountData): string => accountData.avatar || ''
)
export const getCanIndex = createSelector(
  [getAccountData],
  (accountData): boolean => accountData.canIndex || false
)
export const getAccountUserID = createSelector(
  [getAccountData],
  (accountData): number => accountData.userId || 0
)
export const getEmail = createSelector(
  [getAccountData],
  (accountData): string => accountData.email || ''
)
export const getSocialLinks = createSelector(
  [getAccountData],
  (accountData): Record<string, any> => accountData.socialLinks || {}
)
export const getHeroImages = createSelector(
  [getAccountData],
  (accountData): Record<string, any> => accountData.hero || {}
)
export const getArtworks = createSelector(
  [getAccountData],
  (accountData): Array<Record<string, any>> => accountData.artworks || []
)
export const getCollections = createSelector(
  [getAccountData],
  (accountData): Array<Record<string, any>> => accountData.collections || []
)
export const getFavorites = createSelector(
  [getAccountData],
  (accountData): Array<Record<string, any>> => accountData.favorites || []
)
export const getInsideTheStudioImages = createSelector(
  [getAccountData],
  (accountData): Array<Record<string, any>> => accountData.insideTheStudioImages || []
)
export const getArtworksTotal = createSelector(
  [getAccountData],
  (accountData): number => accountData.artworksTotal || 0
)
export const getBadgeTitles = createSelector(
  [getAccountData],
  (accountData): Array<string> =>
    accountData.badges ? accountData.badges.map((item) => item.title) : '' || []
)
export const getArtistDataLayer = createSelector(
  [getAccountUserID, getFullName, getCountry, getBadgeTitles],
  (userId, fullName, country, badgeTitles): Record<string, any> => ({
    environment: 'production',
    event: 'pagetype',
    pagetype: 'userProfile',
    subpagetype: 'profile',
    'artist country': country,
    'artist id': userId,
    'artist name': fullName,
    badges: badgeTitles,
  })
)
export const getCuratorDataLayer = createSelector(
  [getAccountUserID, getFullName, getCountry],
  (userId, fullName, country): Record<string, any> => ({
    environment: 'production',
    event: 'pagetype',
    pagetype: 'curatorProfile',
    subpagetype: 'profile',
    'curator country': country,
    'user id': userId,
    'curator name': fullName,
  })
)
export const getCanRequestCommission = createSelector(
  [getCanIndex, getUserID, getAccountUserID],
  (canIndex, sessionUserId, accountUserId): boolean => {
    const myOwnAccount = sessionUserId === accountUserId
    return !myOwnAccount && canIndex
  }
)
export const getMetaData = createSelector(
  [getAccountType, getAvatar, getFullName],
  (accountType, avatar, fullName): Record<string, any> => {
    const descriptionText = accountType === CURATOR ? 'curated by' : 'like'
    const title = `${fullName} | Saatchi Art`
    const description = `View ${fullName}’s Profile on Saatchi Art. Find art for sale at great prices from artists including Paintings, Photography, Sculpture, and Prints by Top Emerging Artists ${descriptionText} ${fullName}.`
    return {
      description,
      title,
      robots: true,
      og: {
        description,
        image: avatar,
        title,
        type: 'article',
      },
      preloadImages: [],
      schema: {
        webpage: {
          headline: title,
          description,
        },
      },
    }
  }
)