import React from 'react'
import SALink from 'components/SALink'
import { COLLECTION_TITLE, COLLECTIONS, TOOL_TIP_TITLE } from 'lib/user/constants'
import type { ConnectorProps as CollectionsProps } from '../connectors/Collections'
import CollectionsConnector from '../connectors/Collections'
import { displayEllipticalString } from '../helpers'
import { InfoIcon } from './styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const Component = ({
  collections,
  userID,
  sessionUserID,
  accountType,
}: CollectionsProps): React.ReactElement<React.ComponentProps<any>, any> | null => {
  if (!collections.length) {
    return null
  }

  return (
    <div data-section='collection-section'>
      <hr data-type='divider-line' />

      <div data-type='title-section'>
        <SATypography data-type='main-title' variant={SATypographyVariant.H3}>
          {COLLECTION_TITLE[accountType] || COLLECTIONS}
        </SATypography>
        {userID === sessionUserID ? (
          <div data-type='tool-tip'>
            <InfoIcon />
            <span data-type='tool-tip-text'>
              The collections that display here are collections with{' '}
              {TOOL_TIP_TITLE[accountType] || ''} type selected.
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
      <>
        <div data-type='collection-tiles'>
          {collections.map((collection, index) => {
            const { title, artworksCount, artworkImage, collectionUrl } = collection
            return (
              <SALink key={index} data-type='tile' title={`view ${title}`} href={collectionUrl}>
                <picture data-type='image'>
                  <img
                    className='lazyload'
                    loading='lazy'
                    data-src={artworkImage}
                    alt={`${title || 'collection'} thumb`}
                    role='presentation'
                    itemProp='image'
                    data-type='image'
                  />
                </picture>
                <div data-type='overlay'>
                  <span data-type='title'>{displayEllipticalString(title, 20)} </span>
                  <span data-type='artwork-count'>({artworksCount}) </span>
                </div>
              </SALink>
            )
          })}
        </div>
        <SALink
          href={`/account/collection/${userID}`}
          data-type='view-all'
          data-style={accountType.toLowerCase()}
        >
          View All Collections
        </SALink>
      </>
    </div>
  )
}

export default CollectionsConnector(Component)