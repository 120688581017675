import React from 'react'
import type { ConnectorProps as FavoriteProps } from 'containers/SessionFavorites'
import FavoriteConnector from 'containers/SessionFavorites'
import useFavorite from './hook'
import { FavoriteActionItem, HeartIcon, ActionItem } from '../styles'

type Props = FavoriteProps & {
  artworkId: number;
  onSuccess: (...args: Array<any>) => any;
  isOverlayIcon: boolean;
}

const FavoriteIcon = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  // action, clickFavorite, isFavorite, blockEvents
  const { formAction, onClickFavorite, isFavorite, shouldBlockEvents } = useFavorite(props)
  const ContainerComponent = props.isOverlayIcon ? ActionItem : FavoriteActionItem

  return (
    <ContainerComponent
      {...(shouldBlockEvents
        ? {
            'data-blocked': true,
          }
        : {})}
      data-action={formAction}
      data-type='action'
      href='#favorite'
      title={isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
      onClick={onClickFavorite}
    >
      <HeartIcon
        {...(isFavorite
          ? {
              'data-favorite': 'favorite',
            }
          : {})}
      />
    </ContainerComponent>
  )
}

export default FavoriteConnector(FavoriteIcon)