import React, { PureComponent } from 'react'
export type Props = {
  file: Record<string, any>
  children?: React.ReactElement<React.ComponentProps<any>, any>
}
type State = {
  src: (string | null | undefined) | (ArrayBuffer | null | undefined)
}
export default class extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      src: null,
    }
  }

  componentDidMount() {
    if (window.FileReader) {
      const reader = new FileReader()

      reader.onloadend = () => {
        this.setState({
          src: reader.result,
        })
      }

      reader.readAsDataURL(this.props.file)
    }
  }

  render() {
    const { children } = this.props
    const { src } = this.state

    if (!src) {
      return null
    }

    return children ? (
      React.cloneElement(children, {
        src,
      })
    ) : (
      <img src={src} />
    )
  }
}