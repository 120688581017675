import React from 'react'
import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'
import type { ConnectorProps as AdminProps } from '../../connectors/Admin'
import AdminConnector from '../../connectors/Admin'

const AdminOptions = ({
  accountUserID,
  email,
  socialLinks,
}: Record<string, any>): React.ReactElement<React.ComponentProps<any>, any> => {
  const AdminPanel = dynamic(
    import(
      /* webpackChunkName: "adminPanel" */
      './AdminPanel'
    ),
    noSSR
  )
  return <AdminPanel accountUserID={accountUserID} email={email} socialLinks={socialLinks} />
}

const Admin = ({
  accountUserID,
  email,
  isAdmin,
  socialLinks,
}: AdminProps): React.ReactElement<React.ComponentProps<any>, any> | null => {
  if (!isAdmin) {
    return null
  }

  return <AdminOptions accountUserID={accountUserID} email={email} socialLinks={socialLinks} />
}

export default AdminConnector(Admin)