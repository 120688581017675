import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getUserID } from 'selectors/user/session'
import { Actions as FlashMessageActions } from 'actions/shared/flashMessage'
const mapStateToProps = createStructuredSelector({
  sessionUserID: getUserID,
})

const mapDispatchToProps = (dispatch) => ({
  addMessage: (message, type) => {
    dispatch(
      FlashMessageActions.ADD({
        messages: [message],
        type: type || 'error',
      })
    )
  },
})

export type ConnectorProps = {
  addMessage: (...args: Array<any>) => any
  sessionUserID: number
}
export default connect(mapStateToProps, mapDispatchToProps)