/* No Flow - Flow complains about Numbers as Keys */
export const DefaultCollectionTypes = {
  1: 'Community',
  11: 'Portfolio',
}
export const SaatchiCollectionTypes = {
  1: 'Community',
  2: 'Featured',
  3: 'Guest Curator',
  4: 'Saatchi Art',
  5: 'Showdown',
  6: 'New This Week',
  7: 'Chief Curator',
  8: 'Affordable Originals',
  9: 'Prints Under $100',
  10: '100 Curators 100 Days',
  11: 'Portfolio',
  12: 'Art Advisory',
  17: 'AA Selection',
  23: 'Shop by Price',
}
export const LimitedCollectionTypes = {
  21: 'Ready-To-Hang',
  22: 'Gallery Walls',
  24: 'New This Week',
  25: 'Get the Look',
  26: 'Tastemaker Picks',
  27: 'Gift Guide',
  28: 'Trending',
  29: 'Shop by Room',
  30: 'Artist Spotlight',
}
export const SaatchiLatestCollectionTypes = {
  1: 'Community',
  6: 'New This Week',
  11: 'Portfolio',
  17: 'AA Selection',
  20: 'The Other Art Fair',
  34: 'Bestsellers',
  36: 'International Spotlight',
  38: 'Curator Favorites',
  40: 'By Price',
  4: 'All',
  7: 'Chief Curator',
  12: 'Art Advisory',
  18: 'Rising Stars',
  33: 'Featured',
  35: 'Art Styles',
  37: 'Design Trends',
  39: 'Inspired by Art History',
  41: 'Catalog',
  42: 'Trade',
}