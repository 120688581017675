import fetch from 'isomorphic-fetch'
import { multipartFormDataOptions, parseJSON } from 'apis/helpers'
export type PromisePayload = {
  message?: string
  payload: Record<string, any>
  success: boolean
}
export const uploadHeroImage = async (imageFile: Record<string, any>): Promise<PromisePayload> => {
  const formData = new FormData()
  formData.append('image', imageFile)
  const options = { ...multipartFormDataOptions, body: formData }
  const response = await fetch(`/easel_api/my-hero-image/upload`, options)

  if (response.status === 400) {
    const responseJson = await response.json()

    if (responseJson.message) {
      return {
        success: false,
        payload: {
          errorMessage: responseJson.message,
        },
      }
    }
  }

  if (response.status !== 200) {
    return {
      success: false,
      payload: {},
    }
  }

  const json = await parseJSON(response, 'uploadHeroImage')
  return json
}