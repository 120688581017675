import React, { PureComponent } from 'react'
import { DropZoneWrapper } from './styles'
export type Props = {
  children?:
    | React.ReactElement<React.ComponentProps<any>, any>
    | Array<React.ReactElement<React.ComponentProps<any>, any>>
  disabled?: boolean
  disabledText?: string
  onReceiveFile?: (...args: Array<any>) => any
}
type State = {
  dragging: boolean | null | undefined
}
export default class DropZone extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      dragging: false,
    }
  }

  onDrop = (e: DragEvent) => {
    this.overrideEvents(e)
    this.setState({
      dragging: false,
    })

    if (e.dataTransfer && e.dataTransfer.files.length) {
      const { files } = e.dataTransfer

      if (this.props.onReceiveFile) {
        this.props.onReceiveFile(files)
      }
    }
  }
  onDragEnter = (e: DragEvent) => {
    this.overrideEvents(e)
    this.setState({
      dragging: true,
    })
  }
  onDragLeave = (e: DragEvent) => {
    this.overrideEvents(e)
    this.setState({
      dragging: false,
    })
  }
  onFileChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (e.target.files && e.target.files.length) {
      const { files } = e.target

      if (this.props.onReceiveFile) {
        this.props.onReceiveFile(files)
      }
    }
  }
  overrideEvents = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }
  fileUploaderInput: HTMLElement | null | undefined
  browseFiles = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.target.value = ''
    this.fileUploaderInput && this.fileUploaderInput.click()
  }

  render() {
    const { children, disabled } = this.props
    const { dragging } = this.state
    return (
      <DropZoneWrapper
        onClick={this.browseFiles}
        disabled={disabled}
        dragging={dragging ? 'true' : 'false'}
        onDrop={this.onDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrag={this.overrideEvents}
        onDragStart={this.overrideEvents}
        onDragEnd={this.overrideEvents}
        onDragOver={this.overrideEvents}
        data-section='dropzone'
      >
        {children}
        <input
          ref={(el) => (this.fileUploaderInput = el)}
          type='file'
          accept='image/*'
          onChange={this.onFileChanged}
          aria-label='hidden'
        />
      </DropZoneWrapper>
    )
  }
}