import React from 'react'
import styled from 'styled-components'
import TwitterSVG from 'svg/twitter.svg'
export const TwitterIcon = styled(TwitterSVG)`
  fill: #fff;
  height: 25px;
`
type Props = {
  children?: React.ReactElement<React.ComponentProps<any>, any>
  onCallback?: (...args: Array<any>) => any
  text: string
  url: string
}

const Twitter = (props: Props) => {
  const { url, text, onCallback, children } = props
  return (
    <a
      href='#'
      onClick={(ev) => {
        ev.preventDefault()

        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'social share',
            label: 'Twitter',
          })
        }

        window.open(
          `https://twitter.com/intent/tweet?url=${url}&text=${encodeURIComponent(text)}`,
          '',
          'width=450,height=450'
        )

        if (typeof onCallback === 'function') {
          onCallback()
        }
      }}
      data-gtm-event='Twitter'
      aria-label='Share on Twitter'
      data-type='twitter-link'
    >
      <TwitterIcon />
      {children}
    </a>
  )
}

export default Twitter