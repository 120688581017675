/*
This hook is used to filter an Array of items
We can search and select items
Lifecycle:
 Enter | Tab | Click
*/
import { useReducer } from 'react'
import { sortOrderUp, sortOrderDown } from 'components/Sortable/helpers'
import { additionalImage } from 'lib/transformers'
type Props = {
  additionalImages: Record<string, any>
}
type State = {
  files: Array<Record<string, any>>
  submitting: boolean
  uploading: boolean
}
const initialState: State = {
  files: [],
  submitting: false,
  uploading: false,
  uploadId: 0,
}

const reducer = (state: State, data: Record<string, any>) => ({ ...state, ...data })

const useHook = (props: Props): Record<string, any> => {
  const { additionalImages } = props
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    files: additionalImages.map(additionalImage),
    uploadId: additionalImages.length,
  })
  const { files } = state

  const onSortUp = (order: number) =>
    dispatch({
      files: sortOrderUp(files, order),
    })

  const onSortDown = (order: number) =>
    dispatch({
      files: sortOrderDown(files, order),
    })

  const onDelete = (order: number) => {
    dispatch({
      files: files.map((file) => {
        if (file.order === order) {
          file.deleted = true
        } else if (file.order > order) {
          file.order -= 1
        }

        delete file.new
        return file
      }),
    })
    setTimeout(
      () =>
        dispatch({
          files: files.filter((file) => !file.deleted),
        }),
      400
    )
  }

  const updateDescription = (text: string, id: number) => {
    dispatch({
      files: files.map((file) => {
        if (file.id === id) {
          file.description = text
        }

        return file
      }),
    })
  }

  return {
    dispatch,
    onDelete,
    onSortDown,
    onSortUp,
    state,
    updateDescription,
  }
}

export default useHook