import React, { useEffect, useReducer } from 'react'
import type { ConnectorProps as MobileProps } from 'containers/Mobile'
import MobileConnector from 'containers/Mobile'
import { MobileSwipe } from './styles'
type Props = MobileProps & {
  onClick?: (...args: Array<any>) => any
  onSwipeComplete?: (...args: Array<any>) => any
  minimumDragDistance?: number // Minimum Drag distance to Fire Event
}
type State = {
  swipeEventsAvailable: boolean
  // Used to indicate if iPad/Tablet
  isDragging: boolean
  hasCapture: boolean
  // element.setPointerCapture(event.pointerId);
  initialX: number
  // Starting/End Point ( update on `onPointerUp` )
  currentX: number // Current Position ( `isDragging` === true )
}
const initialState: State = {
  isDragging: false,
  hasCapture: false,
  initialX: 0,
  currentX: 0,
}

const reducer = (state: State, data: Record<string, any>) => ({ ...state, ...data })

const extractPositionDelta = (
  ev,
  state = {
    initialX: 0,
  }
): Record<string, any> => {
  const deltaX = ev.pageX - state.initialX
  return {
    deltaX,
  }
}

export default MobileConnector((props: Props): React$ElementRef<any> | null => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { minimumDragDistance, isMobile, onSwipeComplete, onClick } = props
  const { currentX, isDragging, swipeEventsAvailable } = state
  useEffect(() => {
    // Logic to support iPad/Tablet swipes
    const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints || false
    dispatch({
      swipeEventsAvailable: supportsTouch,
    })
  }, [])

  if (!swipeEventsAvailable && !isMobile) {
    return null
  }

  return (
    <MobileSwipe
      onPointerDown={(ev) => {
        ev.currentTarget.setPointerCapture(ev.pointerId) // FIRES onGotPointerCapture

        const { deltaX } = extractPositionDelta(ev)
        dispatch({
          isDragging: true,
          initialX: deltaX,
          currentX: 0,
        })
      }}
      onPointerMove={
        isDragging
          ? (ev) => {
              const { deltaX } = extractPositionDelta(ev, state)
              dispatch({
                currentX: deltaX,
              })
            }
          : null
      }
      onPointerUp={() => {
        if (Math.abs(currentX) > (minimumDragDistance || 20)) {
          const direction = currentX > 0 ? 'previous' : 'next'

          if (onSwipeComplete) {
            onSwipeComplete(direction)
          }
        } else {
          onClick && onClick()
        }

        dispatch(initialState)
      }}
      onPointerCancel={() => {
        dispatch(initialState)
      }}
      onGotPointerCapture={() =>
        dispatch({
          hasCapture: true,
        })
      }
      onLostPointerCapture={() =>
        dispatch({
          hasCapture: false,
        })
      }
    />
  )
})