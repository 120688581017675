import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as ModalActions } from 'actions/shared/modal'
import { getFullName, getAccountUserID, getCanRequestCommission } from '../selectors'
const mapStateToProps = createStructuredSelector({
  artistId: getAccountUserID,
  canRequestCommission: getCanRequestCommission,
  fullName: getFullName,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: (payload: string) => {
    return dispatch(ModalActions.CLOSE_MODAL())
  },
  openModal: (payload: string) => {
    return dispatch(ModalActions.OPEN_MODAL(payload))
  },
})

export type ConnectorProps = {
  artistId: number
  canRequestCommission: boolean
  closeModal: (...args: Array<any>) => any
  fullName: string
  openModal: (...args: Array<any>) => any
}
export default connect(mapStateToProps, mapDispatchToProps)