import React from 'react'
import SALink from 'components/SALink'
import { ArtworksList } from 'components/Artworks/styles/list'
import type { ConnectorProps as ArtworkProps } from '../../connectors/Artworks'
import ArtworkConnector from '../../connectors/Artworks'
import Artwork from './Artwork'
import NoResults from './NoResults'
import { updateDataLayer } from '../../helpers'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const Component = (props: ArtworkProps): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    artistID,
    artworks,
    artworksTotal,
    artistName,
    artistCountry,
    isMobileScreen,
    isMobileTraffic,
    isScreenReady,
    selectedCurrency,
  } = props
  const isMobile = isMobileScreen && isScreenReady
  // PDPs to open in new tabs when in *Desktop* view (NON mobile devices)
  const shouldOpenNewDesktopPdpTab = !isMobileTraffic
  return (
    <div data-section='artworks'>
      {!artworks.length ? (
        <NoResults />
      ) : (
        <>
          <SATypography data-type='main-title' variant={SATypographyVariant.H4}>
            Artworks
          </SATypography>
          <ArtworksList data-device={isMobile ? 'mobile' : 'desktop'} data-style='artist-artwork'>
            {artworks.map((artwork, index) => {
              const priority = index === 0
              const lazyLoad = index > 3
              return (
                <Artwork
                  artwork={artwork}
                  artistCountry={artistCountry}
                  artistName={artistName}
                  isMobile={isMobile}
                  key={`artwork-${index}`}
                  lazyLoad={lazyLoad}
                  priority={priority}
                  dataPosition={index}
                  selectedCurrency={selectedCurrency}
                  shouldOpenNewDesktopPdpTab={shouldOpenNewDesktopPdpTab}
                />
              )
            })}
          </ArtworksList>
          <SALink
            href={`/account/artworks/${artistID}`}
            data-type='view-all'
            title='view all artworks'
            onClick={() =>
              updateDataLayer({
                event: 'artworks',
              })
            }
          >
            <SATypography variant={SATypographyVariant.CTA_LARGE}>
              View All {artworksTotal} Artworks
            </SATypography>
          </SALink>
        </>
      )}
    </div>
  )
}

export default ArtworkConnector(Component)