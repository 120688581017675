import React from 'react'
import SALink from 'components/SALink'
import Follow from 'components/FollowV2'
import Avatar from 'components/Avatar'
import type { ConnectorProps as AppProps } from '../../connectors/App'
import AppConnector from '../../connectors/App'
import Location from './Location'
import AboutArtist from './AboutUser'
import Admin from './Admin'
import Share from '../share'
import Badges from './Badges'
import { numberWithCommas } from '../../helpers'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const Component = (props: AppProps): React.ReactElement<React.ComponentProps<any>, any> => {
  const { accountData, fullName, sessionUserID, artistID } = props
  const {
    avatar,
    firstName,
    lastName,
    aboutArtist,
    state,
    city,
    country,
    countryCode,
    followersTotal,
    userId,
    badges,
  } = accountData
  const myOwnAccount = sessionUserID === artistID
  return (
    <div data-section='user-info'>
      <Avatar avatar={avatar} userID={userId} />
      <div data-type='user-name-container'>
        <SATypography data-type='user-name' variant={SATypographyVariant.H3} component='h1'>
          {firstName} {lastName}
        </SATypography>
      </div>
      <Location state={state} city={city} country={country} countryCode={countryCode} />
      {myOwnAccount && (
        <SALink data-type='edit-profile-link' href='/studio/profile-information'>
          Edit Profile
        </SALink>
      )}
      <hr data-type='divider-line' />
      <div data-type='follow-row'>
        <div data-type='user-following'>
          <SATypography data-type='count' variant={SATypographyVariant.H6}>
            {numberWithCommas(followersTotal)}
          </SATypography>
          <SATypography data-type='follower-text' variant={SATypographyVariant.H6}>
            Followers
          </SATypography>
        </div>
        <Follow artistFullName={fullName} artistID={userId} />
        <Share />
      </div>
      <hr data-type='divider-line' data-style='follow-border' />
      <Badges badges={badges} />
      {badges.length > 0 && <hr data-type='divider-line' data-style='badges-border' />}
      <AboutArtist aboutArtist={aboutArtist} />
      <Admin />
    </div>
  )
}

export default AppConnector(Component)