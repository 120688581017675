"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findLink = findLink;
exports.replaceBadges = replaceBadges;
// Regex - search for <A> tag and pull out HREF && TEXT and Creates Object
// { 0: REGEX MATCH STRING, 1: HREF, 2: TEXT, index: MATCH_INDEX, input: ORIGINAL_STRING  }
// Format Data into a Readable/Easier object to Understand
function findLink(text) {
    const link = /<a\s+[^>]*href=["']([^"]*)["'][^>]*>(.*)<\/a>/i.exec(text);
    if (!link) {
        return null;
    }
    return {
        match: link[0],
        href: link[1],
        text: link[2],
        start: link.index,
        end: link.index + link[0].length,
    };
}
function replaceBadges(badges = []) {
    badges.forEach((badge) => {
        // Replace `Invest in Art` with `Rising Stars`
        if (badge.title.search(/invest in art/gi) > -1) {
            badge.image = badge.image.replace(/invest-in-art/gi, 'rising-stars');
            badge.title = badge.title.replace(/Invest in Art/gi, 'Rising Stars');
            badge.description = badge.description.replace(/Invest in Art/gi, 'Rising Stars');
        }
        // Strip hrefs from any badges that pointed to TOAF urls
        if (badge.description.includes('theotherartfair')) {
            badge.image = badge.image;
            badge.title = badge.title;
            badge.description = badge.description.replace(/<a\b[^>]*>/gi, '').replace(/<\/a>/gi, '');
        }
        // redirect to /catalog only
        if (badge.description.includes('saatchiart.com/catalog/')) {
            const descriptionWithoutIdsInCatalog = badge.description.replace(/^(.* href=").*(".*)$/, (match, firstPart, lastPart) => `${firstPart}/catalog${lastPart}`);
            badge.description = descriptionWithoutIdsInCatalog;
        }
    });
    return badges;
}
