import React from 'react'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyColor, SATypographyVariant } from 'components/SATypography/SATypography.model'
import styles from './Form.module.scss'
import classNames from 'classnames'

type Props = {
  error: string | null | undefined
  label: string
  name: string
  onChange: (...args: Array<any>) => any
  value: string
  isTextArea?: boolean
  customFieldsetBoxStyles?: Record<string, any>
  customFieldsetBoxContainerStyles?: Record<string, any>
  isInline?: boolean
}

const CollectionInput = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    label,
    error,
    name,
    onChange,
    value,
    isTextArea,
    customFieldsetBoxStyles = {},
    customFieldsetBoxContainerStyles = {},
    isInline,
  } = props

  return (
    <fieldset className={styles.fieldsetBox} style={customFieldsetBoxStyles}>
      <div
        className={classNames(styles.fieldsetBoxContainer, {
          [styles.fieldsetBoxContainerInline]: isInline,
          [styles.fieldsetBoxContainerInlineTextarea]: isInline && isTextArea,
        })}
        style={customFieldsetBoxContainerStyles}
      >
        <label
          htmlFor='title'
          className={classNames({
            [styles.flex1]: isInline,
            [styles.labelTextarea]: isInline && isTextArea,
          })}
        >
          <SATypography variant={SATypographyVariant.H6}>{label}</SATypography>
        </label>
        <div
          className={classNames(styles.inputErrorBox, {
            [styles.inputErrorBoxInline]: isInline,
          })}
        >
          {isTextArea ? (
            <textarea
              name={name}
              id={name}
              onChange={onChange}
              error={error}
              value={value}
              rows={4}
              style={{ resize: 'none', flex: 1 }}
            />
          ) : (
            <input
              type='text'
              name={name}
              id={name}
              onChange={onChange}
              error={error}
              value={value}
            />
          )}
          {error && (
            <SATypography
              style={{ paddingTop: '8px' }}
              color={SATypographyColor.ERROR}
              variant={SATypographyVariant.X_SMALL}
            >
              {error}
            </SATypography>
          )}
        </div>
      </div>
    </fieldset>
  )
}

export default CollectionInput
