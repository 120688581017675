import { Observable } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import type { ActionsObservable } from 'redux-observable'
import { ofType } from 'redux-observable'
import type { MiddlewareAPI } from 'redux'
import 'redux'
import type { State } from 'types'
import 'types'
import { addStoreLocaleByCookie } from 'components/GeoLocation/helpers'
import { isAuthenticated } from 'selectors/user/session'
import { getLocation } from 'selectors/shared/location'
import { Actions as FlashMessageActions } from 'actions/shared/flashMessage'
export const checkAuthorization = (state: State) => {
  const authenticated = isAuthenticated(state)

  if (!authenticated) {
    // Redirect if NO AUTH
    const location = getLocation(state)
    const redirect = `/authentication?redirect_url=${encodeURIComponent(location)}&message=1`
    window.location.replace(redirect)
    return false
  }

  return true
}
// This is used for FAILING apis, Redirect users to authentication
export const validateLoginStatus = (response: Record<string, any>): boolean => {
  if (response.loginRequired) {
    const location = addStoreLocaleByCookie(
      `/authentication?redirect_url=${encodeURIComponent(window.location)}`
    )
    window.location = location
    return false
  }

  return true
}
export const rIC =
  typeof requestIdleCallback !== 'undefined'
    ? requestIdleCallback
    : (fn: (...args: Array<any>) => any) => setTimeout(fn, 0)
// Commonly need Setup for every Epic
// To help speed up creating of Epics making a Helper
//
// Usage
// export const myNewEpic = observerEpicHelper(
//   [Constants.SOME_ACTION],
//   async ({ action, observer, store }) => {
//     const state = { ...store.value }
//     const formData = action.payload
//     observer.next(PageActions.SET_DATA({ setSomeData: true }))
//     observer.complete()
//   }
// )
export const observerEpicHelper = (
  actionType: Array<string>,
  epicCallback: (...args: Array<any>) => any
) => (
  action$: ActionsObservable<(...args: Array<any>) => any>,
  store: MiddlewareAPI<Record<string, any>, (...args: Array<any>) => any>
) =>
  action$.pipe(
    ofType(...actionType),
    mergeMap((action) =>
      Observable.create((observer) => {
        rIC(() => {
          ;(async (): Promise<void> => {
            epicCallback({
              action,
              observer,
              store,
            })
          })()
        })
      })
    )
  )
export const dispatchFlashMessage = ({ observer, type, message }: Record<string, any>) => {
  observer.next(
    FlashMessageActions.ADD({
      messages: [message],
      type,
    })
  )
}
export const clearFlashMessage = ({ observer }: Record<string, any>) =>
  observer.next(FlashMessageActions.CLEAR())