import React, { useState } from 'react'
import type { ConnectorProps as InsideTheStudioProps } from '../../connectors/InsideTheStudio'
import InsideTheStudioConnector from '../../connectors/InsideTheStudio'
import Uploader from './Uploader'
import NoResults from './NoResults'
import ImageGallery from './ImageGallery'
import { EditIcon } from '../styles'
type Props = InsideTheStudioProps

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { additionalImages, sessionUserID, accountUserID } = props
  const isMyAccount = sessionUserID === accountUserID
  const [showUploader, setShowUploader] = useState(false)
  const [studioImages, setStudioImages] = useState(additionalImages)

  if (studioImages.length === 0 && !isMyAccount) {
    return null
  }

  return (
    <div data-section='inside-the-studio'>
      <hr data-type='divider-line' />

      <div data-type='title-row'>
        <p data-type='main-title'>inside the studio</p>
        {isMyAccount && (
          <button
            data-type='edit-button'
            type='button'
            onClick={() => setShowUploader(!showUploader)}
          >
            <EditIcon />
          </button>
        )}
      </div>
      {isMyAccount && studioImages.length === 0 && !showUploader && (
        <NoResults showUploader={showUploader} setShowUploader={setShowUploader} />
      )}
      {studioImages.length > 0 && !showUploader && <ImageGallery studioImages={studioImages} />}
      {showUploader && (
        <Uploader
          setShowUploader={setShowUploader}
          setStudioImages={setStudioImages}
          additionalImages={studioImages}
        />
      )}
    </div>
  )
}

export default InsideTheStudioConnector(Component)