import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const ModalLoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  opacity: 0.9;
`
export const LoadingSpinner = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  border: 5px solid ${(props) => props.theme.colors.backgroundGray};
  border-top: 5px solid ${(props) => props.theme.colors.backgroundMediumGray};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
`