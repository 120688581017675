import React from 'react'
import SALink from 'components/SALink'
import Avatar from 'components/Avatar'
import type { ConnectorProps as AppProps } from '../../connectors/App'
import AppConnector from '../../connectors/App'
import Location from './Location'
import AboutArtist from './AboutUser'
import Admin from './Admin'
import Share from '../share'
import { numberWithCommas, updateDataLayer } from '../../helpers'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const Component = (props: AppProps): React.ReactElement<React.ComponentProps<any>, any> => {
  const { accountData } = props
  const {
    avatar,
    firstName,
    lastName,
    aboutArtist,
    state,
    city,
    country,
    countryCode,
    followingTotal,
    userId,
    jobTitle,
  } = accountData
  return (
    <div data-section='user-info' data-style='curator'>
      <Avatar avatar={avatar} userID={userId} />
      <div data-type='curator-badge'> curator </div>
      <SATypography
        data-type='user-name'
        data-style='curator'
        variant={SATypographyVariant.H3}
        component='h1'
      >
        {firstName} {lastName}
      </SATypography>
      <p data-type='job-title'>{jobTitle}</p>
      <Location state={state} city={city} country={country} countryCode={countryCode} />
      <hr data-type='divider-line' />
      <div data-type='follow-row'>
        <SALink
          data-type='user-following'
          data-style='curator-following'
          href={`/account/following/${userId}`}
          title='view following'
          onClick={() =>
            updateDataLayer({
              event: 'following',
            })
          }
        >
          <span data-type='count'>{numberWithCommas(followingTotal)}</span>
          <span data-type='following-text'> Following </span>
        </SALink>
        <Share />
      </div>
      <hr data-type='divider-line' data-style='follow-border' />
      <AboutArtist aboutArtist={aboutArtist} />
      <Admin />
    </div>
  )
}

export default AppConnector(Component)