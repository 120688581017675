import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getIsAdmin } from 'selectors/user/session'
import { getAccountUserID, getEmail, getSocialLinks } from '../selectors'
const mapStateToProps = createStructuredSelector({
  accountUserID: getAccountUserID,
  isAdmin: getIsAdmin,
  email: getEmail,
  socialLinks: getSocialLinks,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  accountUserID: number
  isAdmin: boolean
  email: string
  socialLinks: Record<string, any>
}
export default connect(mapStateToProps, mapDispatchToProps)