import { validateLoginStatus } from 'apis/helpers'
// import { addArtworkToCollectionApi, createNewCollection, fetchCollections } from 'apis/collection'
export const fetchMyCollection = async (): Record<string, any> => {
  const { fetchMyCollections } = await import('apis/collection')
  const { success, payload, loginRequired } = await fetchMyCollections()

  if (!success) {
    validateLoginStatus({
      loginRequired,
    })
    return {
      messages: ['Problem fetching collections for artwork'],
      type: 'error',
    }
  }

  return payload
}
export const fetchCollectionByArtworkId = async (artworkId: number): Record<string, any> => {
  const { fetchMyCollectionsForArtwork } = await import('apis/collection')
  const { success, payload, loginRequired } = await fetchMyCollectionsForArtwork(artworkId)

  if (!success) {
    validateLoginStatus({
      loginRequired,
    })
    return {
      messages: ['Problem fetching collections for artwork'],
      type: 'error',
    }
  }

  return payload
}
type PostParams = {
  artworkId: number
  userCollectionId: number
  action: 'ADD' | 'REMOVE'
}
export const updateArtworkInMyCollection = async ({
  artworkId,
  userCollectionId,
  action,
}: PostParams): Record<string, any> => {
  const { putArtworkInMyCollection, deleteArtworkInMyCollection } = await import('apis/collection')
  const apiEndpoint = action === 'REMOVE' ? deleteArtworkInMyCollection : putArtworkInMyCollection
  const { success, loginRequired } = await apiEndpoint({
    artworkId,
    userCollectionId,
  })

  if (!success) {
    validateLoginStatus({
      loginRequired,
    })
    return {
      messages: [`Problem ${action === 'REMOVE' ? 'removing' : 'adding'} artwork to collection`],
      type: 'error',
    }
  }

  // AlgoliaActions.EVENT({
  //   eventName: action.payload.remove ? 'remove from collection' : 'added to collection',
  //   eventType: 'view',
  //   objectIDs: [artworkId],
  // })
  window.dataLayer.push({
    event: action === 'REMOVE' ? 'removed from collection' : 'added to collection',
  })
  // TODO
  return {
    type: 'success',
  }
}