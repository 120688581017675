import { createSelector } from 'reselect'
import { searchArrayOfObjects } from 'lib/search'
import type { User } from 'types/User'
import 'types/User'
import { getUser } from './helpers'
export const getCollection = createSelector(
  [getUser],
  (user: User): Record<string, any> => {
    return user.collection || {}
  }
)
export const getCollections = createSelector(
  [getCollection],
  (collection): Record<string, any> => collection.collections || {}
)
export const getCollectionsLoaded = createSelector(
  [getCollection],
  (collection): Record<string, any> => collection.collectionsLoaded || false
)
export const getCollectionsByArtworkId = createSelector(
  [getCollection],
  (collection): Record<string, any> => {
    return collection.byArtworkId || {}
  }
)
export const getCollectionByArtworkIdItem = (artworkId: number) =>
  createSelector([getCollectionsByArtworkId], (items): Array<number> => items[artworkId] || [])
// We check if we have already tried to fetch Collections belonging to a Artwork
export const getCollectionByArtworkIdItemExist = (artworkId: number) =>
  createSelector([getCollectionsByArtworkId], (items): boolean => !!items[artworkId])
export const getArtworkCollectionsHasCollectionId = ({
  artworkId,
  collectionId,
}: Record<string, any>) =>
  createSelector(
    [getCollectionByArtworkIdItem(artworkId)],
    (artworkCollections): boolean => artworkCollections.includes(collectionId) || false
  )
export const getCollectionTooltip = createSelector(
  [getCollection],
  (collection): Record<string, any> => collection.tooltip || {}
)
export const getCollectionTooltipIsLoaded = createSelector(
  [getCollectionTooltip],
  (tooltip): boolean => tooltip.isLoaded || false
)
export const getCollectionTooltipCurrentArtwork = createSelector(
  [getCollectionTooltip],
  (tooltip): number => {
    return tooltip.currentArtwork || 0
  }
)
export const getCollectionTooltipItems = createSelector(
  [getCollectionTooltip],
  (tooltip): Array<Record<string, any>> => tooltip.items || []
)
export const getCollectionTooltipSearch = createSelector(
  [getCollectionTooltip],
  (tooltip): string => tooltip.search || ''
)
export const getCollectionTooltipSelectedItems = createSelector(
  [getCollectionsByArtworkId, getCollectionTooltipCurrentArtwork],
  (collections, currentArtwork): Array<Record<string, any>> => {
    return collections[currentArtwork] || []
  }
)
export const getCollectionTooltipSelectedItemsList = createSelector(
  [getCollectionTooltipSelectedItems],
  (collections): Array<string> => {
    return collections.map(({ userCollectionId }): number => userCollectionId || 0) || []
  }
)
export const getCollectionTooltipIsUpdatingItemsList = createSelector(
  [getCollectionTooltipSelectedItems],
  (collections): Array<string> => {
    return (
      collections.map(({ userCollectionId, isUpdating }): number =>
        isUpdating ? userCollectionId : 0
      ) || []
    )
  }
)
export const getCollectionTooltipSelectedItemsExist = (userCollectionId: number) =>
  createSelector(
    [getCollectionTooltipSelectedItemsList],
    (collections): boolean => collections.includes(userCollectionId) || false
  )
export const getCollectionTooltipSearchItems = createSelector(
  [getCollectionTooltipSearch, getCollectionTooltipItems],
  (search, collections): Array<Record<string, any>> =>
    searchArrayOfObjects(search, collections, ['title'])
)