import React from 'react'
import styled from 'styled-components'
import PinterestSVG from 'svg/pinterest.svg'
export const PinterestIcon = styled(PinterestSVG)`
  fill: #fff;
  height: 25px;
`
type Props = {
  children?: React.ReactElement<React.ComponentProps<any>, any>
  media: string
  onCallback?: (...args: Array<any>) => any
  text: string
  url: string
}

const Pinterest = (props: Props) => {
  const { children, url, media, text, onCallback } = props
  return (
    <a
      href='#'
      onClick={(ev) => {
        ev.preventDefault()

        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'social share',
            label: 'Pinterest',
          })
        }

        window.open(
          `http://pinterest.com/pin/create/button/?url=${url}&media=${media}&description=${encodeURIComponent(
            text
          )}`,
          '',
          'width=450,height=450'
        )

        if (typeof onCallback === 'function') {
          onCallback()
        }
      }}
      data-gtm-event='Pinterest'
      aria-label='Share on Pinterest'
      data-type='pinterest-link'
    >
      <PinterestIcon />
      {children}
    </a>
  )
}

export default Pinterest