import React from 'react'
import styles from './SAButton.module.scss'
import { SATypography } from '../SATypography/SATypography'
import { SATypographyVariant } from '../SATypography/SATypography.model'
import classNames from 'classnames'
import { ForwardRefExoticComponent, PropsWithoutRef, ReactNode } from 'react'
import { forwardRef } from 'react'

export interface SAButtonClassnames {
  readonly font: string
}
export type SAButtonVariant = 'contained' | 'outlined' | 'text'

export type SAButtonProps = {
  readonly children: string | number | ReactNode
  readonly variant?: SAButtonVariant
  readonly customButtonTextClassName?: string
  readonly textVariant?: SATypographyVariant
  readonly customButtonClassName?: string
  readonly disabled?: boolean
}

export const SAButton: ForwardRefExoticComponent<PropsWithoutRef<SAButtonProps>> = forwardRef<
  HTMLButtonElement,
  SAButtonProps
>(
  (
    {
      children,
      variant,
      textVariant = SATypographyVariant.CTA_LARGE,
      customButtonTextClassName = '',
      disabled,
      customButtonClassName = '',
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={classNames(styles.sabutton__container, {
          [styles.disabled]: !!disabled,
          [styles.outlined]: variant === 'outlined',
          [styles.text]: variant === 'text',
          [customButtonClassName]: customButtonClassName !== '',
        })}
        {...props}
      >
        <SATypography
          customClassName={classNames(styles.SAButton__buttonText, {
            [customButtonTextClassName]: customButtonTextClassName !== '',
          })}
          variant={textVariant}
        >
          {children}
        </SATypography>
      </button>
    )
  }
)
