import React from 'react'
import styled from 'styled-components'
// import Icon from 'components/Icon'
import FacebookSVG from 'svg/facebook.svg'
export const FacebookIcon = styled(FacebookSVG)`
  fill: #fff;
  height: 25px;
`
type Props = {
  children?: React.ReactElement<React.ComponentProps<any>, any>
  url: string
  onCallback?: (...args: Array<any>) => any
}

const Facebook = (props: Props) => {
  const { children, url, onCallback } = props
  return (
    <a
      href='#'
      onClick={(ev) => {
        ev.preventDefault()

        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'social share',
            label: 'Facebook',
          })
        }

        window.open(`http://facebook.com/sharer.php?u=${url}`, '', 'width=450,height=450')

        if (typeof onCallback === 'function') {
          onCallback()
        }
      }}
      data-gtm-event='Facebook'
      aria-label='Share on Facebook'
      data-type='facebook-link'
      data
    >
      <FacebookIcon />
      {children}
    </a>
  )
}

export default Facebook