import React from 'react'
import SortableListContent from 'components/Sortable/VerticalList'
import HR from 'components/HR'
import ListItem from './ListItem'
type Props = {
  files: Array<Record<string, any>>
  actions: Record<string, (...args: Array<any>) => any>
  app?: string
}

const SortableList = (props: Props) => {
  const { actions, files, app } = props

  if (!files.length) {
    return null
  }

  return (
    <div data-section='sortable' mt={5} mb={2}>
      <div data-type='header'>
        <span data-type='order' width={65}>
          ORDER
        </span>

        <span data-type='image' width={95}>
          IMAGE
        </span>

        <span data-type='caption'>CAPTION</span>
      </div>
      <HR my={2} />

      <SortableListContent items={files} height={100}>
        {files.map((file, index) => (
          <ListItem key={index} index={index} {...file} {...actions} app={app} />
        ))}
      </SortableListContent>
    </div>
  )
}

export default SortableList