import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const UploadAdditionalImage = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;

  [data-section='buttons'] {
    display: flex;
    padding: 10px 0;
    justify-content: flex-start;
    button + button {
      margin-left: 20px;
    }
    justify-content: flex-end;
  }

  [data-section='sortable'] {
    width: 100%;
    margin: 15px 0;
    [data-type='header'] {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        font-weight: bold;
        font-size: 10px;
        color: rgb(51, 51, 51);
      }
    }
    [data-type='order'] {
      width: 80px;
    }
    [data-type='image'] {
      width: 135px;
    }
    [data-type='caption'] {
      width: 100%;
    }
  }

  [data-type='list-item'] {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    [data-type='order'] {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      svg {
        width: 15px;
        padding: 5px 0px;
        cursor: pointer;
        &[data-type='up'] {
          transform: rotate(180deg);
        }
      }
      h2 {
        font-weight: normal;
        margin: 0px;
        font-size: 25px;
      }
    }
    [data-type='image'] {
      img {
        width: 80px;
        height: 80px;
        background: rgb(243, 243, 243);
        object-fit: contain;
      }
    }
    [data-type='caption'] {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-right: 15px;
      input {
        height: 50px;
        padding: 10px;
        border: 1px solid rgb(216, 216, 216);
        color: rgb(51, 51, 51);
        font-size: 14px;
        outline: none;
      }
    }
    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
      place-self: flex-start flex-end;
      path,
      circle {
        stroke: rgb(102, 102, 102);
      }
    }
  }

  [data-section='dropzone'] {
    div {
      padding: 25px;
    }
    div > p {
      font-size: 17px;
      line-height: 1.75em;
    }
  }

  [data-section='buttons'] {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: flex-end;
  }

  button {
    cursor: pointer;
    color: rgb(255, 255, 255);
    min-width: 175px;
    height: 50px;
    padding: 0px 15px;
    background: rgb(35, 31, 32);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    transition: opacity 0.3s ease 0s;
    outline: none;
  }
`
/*
export const Column = styled(DefaultColumn)`
  ${width}
`

export const Buttons = styled(Row)``

export const WarningText = styled.div`
  padding-top: 10px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.red};
`

export const List = styled.ul`
  margin: 0;
  padding: 0 ${(props) => props.theme.space[4]}px;
  color: ${(props) => props.theme.colors.gray};
  li + li {
    margin-top: ${(props) => props.theme.space[1]}px;
  }
`

export const UploadArtwork = styled(Content)`
  align-items: flex-start;
  font-size: 17px;
`

export const Input = styled.input`
  height: 50px;
  padding: ${(props) => props.theme.space[2]}px;
  border: 1px solid #d8d8d8;
  color: ${(props) => props.theme.colors.default};
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  outline: none;
`

export const Image = styled.img`
  width: 80px;
  height: 80px;
  background: ${(props) => props.theme.colors.backgroundGray};
  object-fit: contain;
`

export const Headers = styled.span`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  color: ${(props) => props.theme.colors.default};
  ${width};
`
*/