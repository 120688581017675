import React from 'react'
import { Background, Modal } from 'styles/modals'
type Props = {
  children: React$ElementRef<any>
  closeModal: (...args: Array<any>) => any
  disableClose?: boolean
}
export default ({ children, closeModal, disableClose }: Props) => (
  <>
    <Background
      onClick={
        disableClose
          ? null
          : () => {
              closeModal()
            }
      }
    />
    <Modal data-type='modal'>{children}</Modal>
  </>
)