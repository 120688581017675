import React, { useState } from 'react'
import { validateLoginStatus } from 'epics/helpers'
import type { ConnectorProps as AvatarProps } from 'connectors/Avatar'
import AvatarConnector from 'connectors/Avatar'
import { LoadingSpinner } from 'styles/loader'
import { uploadAvatarImage } from './api'
import { PencilIcon, Avatar } from './styles'
import {updateImagePathBasedOnHostName} from '../../apps/public/profile/artistProfile/helpers'
type Props = AvatarProps & {
  userID: number
  avatar: string
}

const uploadAvatarImageApi = async ({
  imageFile,
  callbackFunc,
  addMessage,
}: Record<string, any>) => {
  const heroImageApi = await uploadAvatarImage(imageFile)
  validateLoginStatus(heroImageApi)

  if (heroImageApi.success) {
    const { medImage } = heroImageApi.payload
    callbackFunc({
      avatarImg: medImage,
    })
  } else {
    const { errorMessage } = heroImageApi.payload
    let flashErrorMessage = 'Profile image could not be uploaded'

    if (errorMessage) {
      flashErrorMessage = errorMessage
    }

    addMessage(flashErrorMessage, 'error')
    callbackFunc({
      isLoading: false,
    })
  }
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { userID, sessionUserID, avatar, addMessage } = props
  const [state, handleState] = useState({
    isLoading: false,
    avatarImg: avatar,
  })
  const { isLoading, avatarImg } = state
  const myOwnAccount = sessionUserID === Number(userID)

  const onFileChanged = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (e.target.files && e.target.files.length) {
      const { files } = e.target
      const imageFile = files[0]
      handleState((prevState) => ({ ...prevState, isLoading: true }))
      uploadAvatarImageApi({
        imageFile,
        callbackFunc: (data: Record<string, any>) => {
          handleState((prevState) => ({ ...prevState, isLoading: false, ...data }))
        },
        addMessage,
      })
    }
  }

  return (
    <Avatar>
      {isLoading && (
        <>
          <div data-type='overlay' />
          <LoadingSpinner data-type='loader' />
        </>
      )}
      <img
        src={updateImagePathBasedOnHostName(avatarImg)}
        data-type='user-avatar'
        alt='profile avatar'
        role='presentation'
        itemProp='image'
      />
      {myOwnAccount ? (
        <>
          <input
            data-type='upload-input'
            type='file'
            accept='image/png, image/jpeg, image/jpg'
            onChange={onFileChanged}
            aria-label='hidden'
          />
          <div data-type='edit-avatar-link' title='edit avatar'>
            <PencilIcon />
          </div>
        </>
      ) : (
        ''
      )}
    </Avatar>
  )
}

export default AvatarConnector(Component)