export const reorderArray = (arr: Array<any>, from: number, to: number): Array<any> => {
  arr = JSON.parse(JSON.stringify(arr))
  const order = arr.slice()

  if (to >= 0) {
    const val = order[from]
    order.splice(from, 1)
    order.splice(to, 0, val)
  }

  return order
}

const sortUp = (arr: Array<any>, index: number): Array<any> => reorderArray(arr, index, index - 1)

const sortDown = (arr: Array<any>, index: number): Array<any> => reorderArray(arr, index, index + 1)

// Expects Array<{id, order}> to reorder
const resortOrderKey = (originalArray: Array<any>, newArray: Array<any>, key: string): Array<any> =>
  originalArray.map((obj) => ({
    ...obj,
    order: newArray.findIndex((item) => item[key] === obj[key]),
  }))

const sortByOrder = (arr) => arr.slice().sort((a, b) => (a.order < b.order ? -1 : 1))

export const sortOrderUp = (arr: Array<any>, index: number): Array<any> =>
  resortOrderKey(arr, sortUp(sortByOrder(arr), index), 'id')
export const sortOrderDown = (arr: Array<any>, index: number): Array<any> =>
  resortOrderKey(arr, sortDown(sortByOrder(arr), index), 'id')