import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import Pencil from 'svg/pencil-new.svg'
export const Avatar = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  [data-type='overlay'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 15;
    border-radius: 50%;
  }
  [data-type='loader'] {
    position: absolute;
    top: 45%;
    left: 42%;
    z-index: 16;
  }
  [data-type='user-avatar'] {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 4px solid #ffffff;
  }
  [data-type='edit-avatar-link'] {
    background-color: #ffffff;
    border-radius: 50%;
    height: 60px;
    padding: 15px 20px;
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  [data-type='upload-input'] {
    opacity: 0;
    height: 200px;
    width: 200px;
    z-index: 10;
    cursor: pointer;
    position: absolute;
  }
  &:hover {
    [data-type='edit-avatar-link'] {
      display: block;
    }
  }

  @media (max-width: 992px) {
    [data-type='user-avatar'] {
      width: 100px;
      height: 100px;
    }
  }
`
export const PencilIcon = styled(Pencil)`
  position: absolute;
  left: 20px;
  top: 20px;
  width: 19px;
  height: 19px;
`