import React, { useState } from 'react'
import VisibilityTrigger from 'components/VisibilityTrigger'
import Hero from '../components/Hero'
import ArtistInfo from '../components/userInfo/ArtistInfo'
import Artworks from '../components/artworks'
import ViewArtworks from '../components/ViewArtworks'
import RequestCommission from '../components/RequestCommission'
import Carousels from '../components/Carousels'
import Collections from '../components/Collections'
import InsideTheStudio from '../components/insideTheStudio'
import { Layout } from '../components/styles'

const Component = (): React.ReactElement<React.ComponentProps<any>, any> => {
  const [shouldLoadComponent, setShouldLoadComponent] = useState(false)
  return (
    <Layout>
      <Hero />
      <ViewArtworks />
      <div data-section='main-content'>
        <ArtistInfo />
        <div data-section='right-col'>
          <Artworks />
          <InsideTheStudio />
          <Collections />
          <VisibilityTrigger setShouldLoadComponent={setShouldLoadComponent} />
          <RequestCommission />
        </div>
      </div>
      <div data-type='carousel-wrapper'>
        {/* Only show if artist has 5 artworks or less */}
        <Carousels shouldLoadComponent={shouldLoadComponent} />
      </div>
    </Layout>
  )
}

export default Component