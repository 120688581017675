import type { ValidationRuleset } from 'lib/validation'
import { lengthValidatorCreator, notEmptyValidator } from 'lib/validation'
const AA_ERROR_MESSAGE = 'required for AA Selection'
// Ensure Collections AA(17) selection contain the Additional Data Params
export const aaValidationRule = (value: string, data: Record<string, any>): boolean => {
  const { firstName, lastName, email, zendeskTicketId, collectionTypeIds } = data

  if (!collectionTypeIds.includes('17')) {
    return true
  }

  if ((firstName && lastName && email) || zendeskTicketId) {
    return true
  }

  return false
}
// Validation Rules
export const formValidationRules: ValidationRuleset = {
  title: [
    { ...notEmptyValidator, message: () => 'Please enter a Title' },
    {
      ...lengthValidatorCreator({
        min: 0,
        max: 150,
      }),
      message: () => '150 max characters length',
    },
  ],
  header: [
    {
      ...lengthValidatorCreator({
        min: 0,
        max: 25,
      }),
      message: () => '25 max characters length',
    },
  ],
  subtitle: [
    {
      ...lengthValidatorCreator({
        min: 0,
        max: 50,
      }),
      message: () => '50 max characters length',
    },
  ],
  description: [
    {
      ...lengthValidatorCreator({
        min: 0,
        max: 750,
      }),
      message: () => '750 max characters length',
    },
  ],
  // AA CustomRule
  firstName: [
    {
      validate: aaValidationRule,
      message: () => `First Name ${AA_ERROR_MESSAGE}`,
    },
  ],
  lastName: [
    {
      validate: aaValidationRule,
      message: () => `Last Name ${AA_ERROR_MESSAGE}`,
    },
  ],
  email: [
    {
      validate: aaValidationRule,
      message: () => `Email ${AA_ERROR_MESSAGE}`,
    },
  ],
  zendeskTicketId: [
    {
      validate: aaValidationRule,
      message: () => `Zendesk Ticket ID ${AA_ERROR_MESSAGE}`,
    },
  ],
  collectionTypeIds: [
    { ...notEmptyValidator, message: () => 'Please select at least one Collection Type' },
  ],
}