import React from 'react'
import HR from 'components/HR'
import { H2 } from 'components/Serif'
import FileReader from 'components/FileReader'
import type { AdditionalImagesParams } from 'types/Upload'
import 'types/Upload'
import CaretIcon from 'svg/caret-down.svg'
import CloseIcon from 'svg/close-circle.svg'
type Props = AdditionalImagesParams & {
  data: Record<string, any>
  description: string | null | undefined
  id: number
  is_staging?: '1'
  onDelete: (...args: Array<any>) => any
  onSortDown: (...args: Array<any>) => any
  onSortUp: (...args: Array<any>) => any
  order: number
  thumb: string | null | undefined
  updateDescription: (...args: Array<any>) => any
  app?: string
}
export default (props: Props) => {
  const {
    data,
    description,
    order,
    id,
    is_staging: isStaging,
    onDelete,
    onSortDown,
    onSortUp,
    thumb,
    updateDescription,
    app,
  } = props
  const isArtistProfileApp = app === 'artist-profile'
  return (
    <>
      <div data-type='list-item'>
        <div data-type='order'>
          <CaretIcon data-type='up' onClick={() => onSortUp(order)}>
            UP
          </CaretIcon>

          <H2 pl='2px'>{Number(order) + 1}</H2>

          <CaretIcon data-tyep='down' onClick={() => onSortDown(order)}>
            Down
          </CaretIcon>
        </div>

        <div data-type='image'>
          {isStaging ? (
            <FileReader file={data}>
              <img alt={id} />
            </FileReader>
          ) : (
            <img alt={id} src={thumb} />
          )}
        </div>

        <div data-type='caption'>
          <input
            type='text'
            onChange={(ev) => updateDescription(ev.currentTarget.value, id)}
            value={description || ''}
            placeholder={isArtistProfileApp ? 'Max 50 characters...' : 'Enter text...'}
            aria-label='hidden'
            maxLength={isArtistProfileApp ? 50 : ''}
          />
        </div>

        <div data-type='delete'>
          <CloseIcon onClick={() => onDelete(order)} />
        </div>
      </div>
      <HR my={2} />
    </>
  )
}