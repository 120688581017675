import React from 'react'
import styled from 'styled-components'
import EnvelopeSVG from 'svg/envelope.svg'
export const MailIcon = styled(EnvelopeSVG)`
  fill: #fff;
  height: 25px;
`
type Props = {
  children?: React.ReactElement<React.ComponentProps<any>, any>
  body: string
  onCallback?: (...args: Array<any>) => any
  subject: string
}

const Mail = (props: Props) => {
  const { children, subject, body, onCallback } = props
  return (
    <a
      style={{
        textDecoration: 'none',
      }}
      href={`mailto:?to=&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}
      data-gtm-event='Email'
      onClick={() => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'social share',
            label: 'Email',
          })
        }

        if (typeof onCallback === 'function') {
          onCallback()
        }
      }}
      aria-label='Share via Email'
      data-type='email-link'
    >
      <MailIcon />
      {children}
    </a>
  )
}

export default Mail