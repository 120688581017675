export const DESKTOP_COLUMN_WIDTH = 320 // max width of Artwork Data

export const SPONSORED_ITEM_DESKTOP_COLUMN_WIDTH = 280 // max width of Sponsored Artwork Data

export const DESKTOP_COLUMN_GAP = 30 // max width of Artwork Data

export const MOBILE_COLUMN_WIDTH = 140 // 2 Column Experience Mobile

export const MOBILE_COLUMN_GAP = 10 // 2 Column Experience Mobile

export const SPAN_HEIGHT = 25 // grid-auto-rows: 25px;

export const IMAGE_DASH6_MAX_WIDTH = 375 // Max width -6 image size