import React, { PureComponent } from 'react'
import Animation from 'components/Animations'
type Props = {
  items: Array<any>
  height: number
  children: Array<React.ReactElement<React.ComponentProps<any>, any>>
}
export default class extends PureComponent<Props> {
  render() {
    const { children, height, items } = this.props
    const outerProps = {
      defaultStyles: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      },
      enter: {
        height: height * items.length,
        opacity: 1,
      },
      update: {
        height: height * items.length,
        opacity: 1,
      },
      isVisible: true,
    }
    return (
      <Animation {...outerProps}>
        {React.Children.map(children, (child, i) => {
          const itemProps = {
            defaultStyles: {
              willChange: 'transform, height, opacity',
              width: '100%',
              position: 'absolute',
              overflow: 'hidden',
            },
            from: items[i].new
              ? {
                  height: 0,
                  opacity: 0,
                }
              : {},
            enter: {
              transform: `translate3d(0, ${height * (items[i].order || 0)}px, 0)`,
              opacity: 1,
              height,
            },
            update: {
              transform: `translate3d(0, ${height * (items[i].order || 0)}px, 0)`,
              opacity: 1,
              height,
            },
            key: items[i].id,
            isVisible: !items[i].deleted,
          }
          return <Animation {...itemProps}>{child}</Animation>
        })}
      </Animation>
    )
  }
}