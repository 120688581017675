// Server will store everything as Kilograms or Centimeters in Database
// We will determine Conversion based on MeasurementUnit ( IN => LBs | CM => KGs )
// With these Functions we always skip conversion if CENTIMETERS
// `ForServer` is for Sending Users info To Server
// `ForClient` is for Showing Users preference on Client
// `Size` => IN || CM
// `Weight` => LB || KG
import type { MeasurementUnit } from 'types/User/Session'
import 'types/User/Session'
export const convertSizeForServer = (
  val: number,
  measurementUnit: MeasurementUnit = 'in'
): number => {
  let size = Number(val || 0)

  if (measurementUnit === 'in') {
    size /= 0.393701
  }

  // 1.004 => 1 || 15.980 => 15.98
  return parseFloat(size) <= 0.1 ? 0.1 : parseFloat(size.toFixed(1))
}
export const payloadDimensionsConverter = (
  data: Record<string, any>,
  measurementUnit: MeasurementUnit = 'in',
  direction: string
): Record<string, any> => {
  const { width, height, depth } = data
  const action = direction === 'forServer' ? convertSizeForServer : convertSizeForClient
  const cleanWidth = action(width, measurementUnit)
  const cleanDepth = action(depth, measurementUnit)
  const cleanHeight = action(height, measurementUnit)
  const newPayload = {
    ...data,
    ...{
      height: cleanHeight,
      width: cleanWidth,
      depth: cleanDepth,
    },
  }
  return newPayload
}
export const convertSizeForClient = (
  val: number,
  measurementUnit: MeasurementUnit = 'in'
): number => {
  let size = Number(val || 0)

  if (measurementUnit === 'in') {
    size *= 0.393701
  }

  // 1.004 => 1 || 15.980 => 15.98
  return parseFloat(size) <= 0.1 ? 0.1 : parseFloat(size.toFixed(1))
}
// Updated conversion for Print display on PDP
export const convertPrintInchesToCentimeters = (val: number, userSizePreference: string) => {
  if (userSizePreference === 'in') {
    return val
  }

  let size = Number(val || 0)

  if (userSizePreference === 'cm') {
    size *= 2.54
  }

  return parseFloat(size.toFixed(1))
}
// Clientside Preference
export const formatDimensionInCm = convertSizeForClient
export const convertWeightForServer = (
  val: number,
  measurementUnit: MeasurementUnit = 'in'
): number => {
  let size = Number(val || 0)

  if (measurementUnit === 'in') {
    size *= 0.45359237
  }

  return parseFloat(size) <= 0.1 ? 0.1 : parseFloat(size.toFixed(2))
}
export const convertWeightForClient = (
  val: number,
  measurementUnit: MeasurementUnit = 'in'
): number => {
  let size = Number(val || 0)

  if (measurementUnit === 'in') {
    size /= 0.45359237
  }

  return parseFloat(size) <= 0.1 ? 0.1 : parseFloat(size.toFixed(2))
}
export const getWeightUnit = (measurementUnit: 'in' | 'cm' = 'in'): string =>
  measurementUnit === 'in' ? 'lb' : 'kg'