import styled from 'styled-components'
import { space } from 'styled-system'
export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const Row = styled.div`
  width: 100%;
  display: flex;
`
export const UploaderBox = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.space[5]}px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.backgroundGrayLight};
  border: 1px solid ${(props) => props.theme.colors.footerBackground};
  color: ${(props) => props.theme.colors.lightGray};
  pointer-events: none;
`
export const Text = styled.p`
  color: ${(props) => props.theme.colors.default};
  margin: 0 0 ${(props) => props.theme.space[3]}px;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    ${(props) => props.hideOnMobile && `display: none;`}
  }
`
export const Upload = styled.p`
  ${space};
  color: ${(props) => props.theme.colors.default};
  border-bottom: 1px solid ${(props) => props.theme.colors.default};
  padding-bottom: ${(props) => props.theme.space[1]}px;
  cursor: pointer;
`
export const DropZoneWrapper = styled.div`
  position: relative;
  width: 100%;
  background: #f9f9f9;
  padding: 40px auto;
  transition: 0.2s ease opacity;
  cursor: pointer;
  ${(props) =>
    props.dragging === 'true' &&
    `
    opacity: .5;
  `}
  input {
    display: none;
  }
  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
    pointer-events: none;`}
`