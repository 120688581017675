import React, { useEffect, useRef } from 'react'
import SALink from 'components/SALink'
import {
  DESKTOP_COLUMN_WIDTH,
  MOBILE_COLUMN_WIDTH,
  SPAN_HEIGHT,
} from 'components/Artworks/constants'
import Favorite from 'components/Artworks/Actions/Favorites/Action'
import AddToCart, { gaAddToCartWhitelist } from 'components/Artworks/Actions/AddToCart'
import CollectionTooltip, { CollectionTooltipApi } from 'components/Collection/V2/Tooltip'
import {
  convertDefaultToLocaleCurrency,
  formatPrice,
  convertFromCentsToDollars,
} from 'components/FormatPrice/helpers'
import { getDataLayerItemVariant } from 'lib/helpers'
import useIntersectionObserver from 'hooks/intersectionObserver'
import { Dimensions } from 'components/MeasurementUnit'
import DataLayer, { type Impression } from 'lib/dataLayer'
import DataLayerOld, { type ImpressionOld } from 'lib/dataLayerOld'
import Image from './Image'
import { AddToCollectionIcon } from '../styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'
import CreateCollectionsModal, { CreateCollectionsModalApi } from 'apps/saatchi/collection/layouts/Modal'

type Props = {
  artistName: string
  artistCountry: string
  artwork: Record<string, any>
  isMobile: boolean
  lazyLoad: boolean
  priority: boolean
  selectedCurrency: Record<string, any>
  shouldOpenNewDesktopPdpTab: boolean,
  dataPosition: number,
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const ecommerceRef = useRef(null)
  const {
    artwork,
    artistName,
    artistCountry,
    isMobile,
    lazyLoad,
    priority,
    selectedCurrency,
    shouldOpenNewDesktopPdpTab,
    dataPosition,
  } = props
  // We need to figure out Height/SPAN size of Image
  const {
    artworkID,
    artworkImage,
    aspectRatio,
    category,
    depth,
    height,
    originalStatus,
    pdpUrl,
    printPrice,
    productSku,
    subject,
    styles,
    title,
    width,
    listPrice,
  } = artwork

  const createCollectionsModalRef = useRef<CreateCollectionsModalApi>(null)
  const collectionTooltipRef = useRef<CollectionTooltipApi>(null)

  const handleOpenCreateCollectionsModal = () => {
    createCollectionsModalRef.current?.handleOpenModal()
  }

  const handleCreateCollectionOnSuccess = (payload) => {
    collectionTooltipRef.current?.addArtworkToCollection(payload)
  }

  const dataList = 'artist-profile-results'
  const dataListId = 'artist-profile-results'
  const dataListName = artistName
  const columnWidth = isMobile ? MOBILE_COLUMN_WIDTH : DESKTOP_COLUMN_WIDTH
  const imageHeight = parseInt(columnWidth / aspectRatio, 10)
  const imageSpanCount = Math.round(imageHeight / SPAN_HEIGHT)
  // Artwork info is a constant SPAN siz
  const infoSpanCount = isMobile ? 5 : 6
  const totalSpanCount = imageSpanCount + infoSpanCount
  // Helpers to PASS only props NEEDED
  const dollarToCents = convertFromCentsToDollars(parseInt(listPrice, 10))
  const localePrice = formatPrice(
    convertDefaultToLocaleCurrency(dollarToCents, selectedCurrency),
    selectedCurrency
  )
  const dollarToCentsPrint = convertFromCentsToDollars(parseInt(printPrice, 10))
  const localePrintPrice = formatPrice(
    convertDefaultToLocaleCurrency(dollarToCentsPrint, selectedCurrency),
    selectedCurrency
  )

  const { hasViewed } = useIntersectionObserver(ecommerceRef)
  useEffect(() => {
    if (hasViewed) {
      // Delete this dataLayer event in July as part of universal analytics sunset
      const impressionOld: ImpressionOld = {
        // Strip trailing `s` - Paintings => Painting
        // Change Mixed Media => Mixedmedia
        category: category.replace(/s$/, '').replace(/\sM/, 'm'),
        id: productSku,
        list: dataList,
        name: title,
        position: dataPosition,
      }
      DataLayerOld.addImpressionEventOld(impressionOld)
      // This is the new dataLayer event for GA4
      const impression: Impression = {
        // Strip trailing `s` - Paintings => Painting
        // Change Mixed Media => Mixedmedia
        item_category: category.replace(/s$/, '').replace(/\sM/, 'm'),
        item_id: productSku || '',
        item_art_id: artworkID || '',
        // item_list_id: dataListId || '',
        // item_list_name: dataListName || '',
        item_name: title || '',
        item_brand: artistName || '',
        item_variant: getDataLayerItemVariant(productSku),
        index: dataPosition,
        affiliation: 'Online Store',
        price: convertFromCentsToDollars(listPrice) || 0,
        quantity: 1,
        // original_availability: originalStatus,
      }
      DataLayer.addImpressionEvent(impression, dataListId, dataListName)
    }
  }, [hasViewed])

  const handleViewProduct = () => {
    // Delete this dataLayer event in July as part of universal analytics sunset
    productSku &&
      window.dataLayer.push({
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: {
              dataList, // feature-results
            },
            products: [
              {
                productSku, // Product sku
                dataPosition, // Location in the results
                title, // artwork title
              },
            ],
          },
        },
      })
    // This is the new dataLayer event for GA4
    // Clear the previous ecommerce object.
    window.dataLayer.push({ ecommerce: null })
    productSku &&
      window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
          item_list_id: dataListId,
          item_list_name: dataListName,
          items: [
            {
              item_category: category.replace(/s$/, '').replace(/\sM/, 'm'),
              item_id: productSku || '',
              item_art_id: artworkID || '',
              item_name: title || '',
              item_brand: artistName || '',
              item_variant: getDataLayerItemVariant(productSku),
              index: dataPosition,
              item_list_id: dataListId,
              item_list_name: dataListName,
              affiliation: 'Online Store',
              price: convertFromCentsToDollars(listPrice) || 0,
              quantity: 1,
            },
          ],
        },
      })
  }

  return (
    <figure style={{ gridRowEnd: `span ${totalSpanCount}` }} ref={ecommerceRef}>
      <Image
        shouldOpenNewDesktopPdpTab={shouldOpenNewDesktopPdpTab}
        lazyLoad={lazyLoad}
        priority={priority}
        artworkImage={artworkImage}
        pdpUrl={pdpUrl}
        title={title}
        width={width}
        height={height}
        originalStatus={originalStatus}
        category={category}
        subject={subject}
        styles={styles}
        artistName={artistName}
        onClick={handleViewProduct}
      />
      <div data-type='artwork-info-container'>
        <div data-type='actions'>
          <div data-type='artwork-actions'>
            <Favorite artworkId={Number(artworkID)} dataStyle='profile' />
            {originalStatus === 'for-sale' && (
              <AddToCart
                productEvent={gaAddToCartWhitelist({
                  artistName,
                  category,
                  listId: 'artist-profile-artworks',
                  price: listPrice,
                  sku: productSku,
                  title,
                  artworkId: artworkID,
                })}
                artworkId={Number(artworkID)}
                sku={productSku}
                status='avail'
                eventType='artist-profile-artworks'
              />
            )}
            <div title='Add to Collection' data-action='add-to-collection'>
              <CollectionTooltip artworkId={Number(artworkID)} handleOpenCreateCollectionsModal={handleOpenCreateCollectionsModal} ref={collectionTooltipRef}>
                <AddToCollectionIcon />
              </CollectionTooltip>
            </div>
          </div>
          {originalStatus === 'sold' && <div data-type='sold-badge'> sold </div>}
        </div>
        <div data-type='artwork-info'>
          <SALink
            href={pdpUrl}
            title={`${title} ${category}`}
            onClick={handleViewProduct}
            data-type='artwork-title'
            target={shouldOpenNewDesktopPdpTab ? '_blank' : ''}
          >
            <SATypography truncateText variant={SATypographyVariant.H6}>{title}</SATypography>
          </SALink>
          {listPrice !== 0 && (originalStatus === 'for-sale' || originalStatus === 'sold') && (
            <SATypography data-type='price' variant={SATypographyVariant.H6}>{localePrice}</SATypography>
          )}
        </div>
        <div data-type='info-row'>
          <SATypography data-type='title-dim' variant={SATypographyVariant.X_SMALL}>
            {category.replace(/-/g, ' ')}
            <>
              ,&nbsp;
              <Dimensions
                dimensions={{
                  width,
                  height,
                  depth,
                }}
              />
            </>
          </SATypography>
        </div>
        {printPrice > 0 && (
          <div data-type='country-print-price'>
            <SATypography variant={SATypographyVariant.X_SMALL}>Prints from {localePrintPrice}</SATypography>
          </div>
        )}
      </div>

      <CreateCollectionsModal
        ref={createCollectionsModalRef}
        onSuccess={handleCreateCollectionOnSuccess}
      />
    </figure>
  )
}

export default Component
