import React from 'react'
import Head from 'next/head'
import SALink from 'components/SALink'
import { getImageAltText } from 'components/Artworks/Image/helpers'
import { ArtworkImage } from '../styles'
import { updateCriticalImagePath } from 'lib/helpers'
type Props = {
  shouldOpenNewDesktopPdpTab: boolean
  artworkImage: string
  pdpUrl: string
  title: string
  priority: boolean
  lazyLoad: boolean
  width: number
  height: number
  originalStatus: string
  category: string
  artistName: string
  subject: string
  styles: ReadonlyArray<string>
  onClick: () => void
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    shouldOpenNewDesktopPdpTab,
    artworkImage,
    pdpUrl,
    title,
    priority,
    lazyLoad,
    width,
    height,
    originalStatus,
    category,
    artistName,
    subject,
    styles,
    onClick,
  } = props
  let imageSrc = artworkImage
  const IMAGE_SUBFOLDER = 'saatchi-images'
  if (priority) imageSrc = updateCriticalImagePath(IMAGE_SUBFOLDER, imageSrc)
  return (
    <>
      {priority && (
        /* Preload Images */
        <Head>
          <link
            key={`__nimg-${artworkImage}`}
            rel='preload'
            fetchPriority='high'
            as='image'
            href={imageSrc}
          />
        </Head>
      )}
      <ArtworkImage data-type='artwork-image'>
        <SALink
          href={pdpUrl}
          title={`${title} ${category}`}
          target={shouldOpenNewDesktopPdpTab ? '_blank' : ''}
          onClick={onClick}
        >
          <picture data-type='image'>
            <img
              {...(lazyLoad
                ? {
                    className: 'lazyload',
                    'data-src': imageSrc,
                    loading: 'lazy',
                  }
                : {
                    src: imageSrc,
                  })}
              width={width}
              height={height}
              alt={getImageAltText({
                artistName,
                subject,
                styles,
                category,
              })}
              role='presentation'
              itemProp='image'
              data-type='image'
            />
          </picture>
        </SALink>
        {originalStatus === 'reserved' && <div data-type='reserved-label'>reserved</div>}
      </ArtworkImage>
    </>
  )
}

export default Component
