import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getSelectedCurrency } from 'selectors/user/cookie'
import {
  getAccountUserID,
  getArtworksTotal,
  getFullName,
  getIsArtist,
  getIsMobileTraffic,
  isSessionSet,
} from '../selectors'
const mapStateToProps = createStructuredSelector({
  artistId: getAccountUserID,
  artistName: getFullName,
  artworksTotal: getArtworksTotal,
  isArtist: getIsArtist,
  isMobile: getIsMobileTraffic,
  isSessionSet,
  selectedCurrency: getSelectedCurrency,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  artistId: number
  artistName: string
  artworksTotal: number
  isArtist: boolean
  isMobile: boolean
  isSessionSet: boolean
  selectedCurrency: Record<string, any>
}
export default connect(mapStateToProps, mapDispatchToProps)