import React from 'react'
import styled from 'styled-components'
// import Icon from 'components/Icon'
import LinkedInSVG from 'svg/linkedin.svg'
export const LinkedInIcon = styled(LinkedInSVG)`
  fill: #333;
  height: 25px;
`
type Props = {
  children?: React.ReactElement<React.ComponentProps<any>, any>
  url: string
  onCallback?: (...args: Array<any>) => any
}

const LinkedIn = (props: Props) => {
  const { children, url, onCallback } = props
  return (
    <a
      href='#'
      onClick={(ev) => {
        ev.preventDefault()

        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'social share',
            label: 'LinkedIn',
          })
        }

        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
          '',
          'width=450,height=450'
        )

        if (typeof onCallback === 'function') {
          onCallback()
        }
      }}
      data-gtm-event='LinkedIn'
      aria-label='Share on LinkedIn'
      data-type='linkedIn-link'
      data
    >
      <LinkedInIcon />
      {children}
    </a>
  )
}

export default LinkedIn