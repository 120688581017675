import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'
import Modal from 'components/Modal/v2'
import type { ConnectorProps as RequestCommissionProps } from '../connectors/RequestCommission'
import RequestCommissionConnector from '../connectors/RequestCommission'
import { updateDataLayer } from '../helpers'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const LaunchModal = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> => {
  const CommissionModal = dynamic(
    import(
      /* webpackChunkName: "artistCommissionModal" */
      'components/RequestCommission/CommissionModal'
    ),
    noSSR
  )
  return <CommissionModal {...props} />
}

type Props = RequestCommissionProps & {
  closeModal: (...args: Array<any>) => any
  openModal: (...args: Array<any>) => any
}

const Component = (props: Props): React$ElementRef<any> | null => {
  const { artistId, fullName, closeModal, canRequestCommission, openModal } = props
  const [showModal, handleShowModal] = useState(false)

  if (!canRequestCommission) {
    return null
  }

  return (
    <div data-section='request-commission'>
      <SATypography data-type='title' variant={SATypographyVariant.H3}>
        Commission {fullName}
      </SATypography>
      <SATypography data-type='sub-title' variant={SATypographyVariant.SMALL}>
        If you’re interested in commissioning this artist for a custom artwork, inquire here.
      </SATypography>
      <button
        type='button'
        data-type='req-commission-button'
        onClick={() => {
          updateDataLayer({
            event: 'commission start',
          })
          handleShowModal(true)
          openModal('commissionModal')
        }}
      >
      <SATypography variant={SATypographyVariant.CTA_LARGE}>
        Contact Our Curators
      </SATypography>
      </button>
      {showModal && (
        <Modal name='commissionModal'>
          <LaunchModal closeModal={closeModal} artistId={artistId} page='artist-profile' />
        </Modal>
      )}
    </div>
  )
}

export default RequestCommissionConnector(Component)