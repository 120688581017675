import React from 'react'
import type { Props as DropZoneProps } from './DropZone'
import DropZone from './DropZone'
import { UploaderBox, Text, Upload } from './styles'
type Props = {
  disabled?: boolean
  disabledText?: string
  bottomMargin?: string
} & DropZoneProps
export const UploadContent = (props: Props) => {
  const { disabled, disabledText, bottomMargin } = props
  return (
    <UploaderBox>
      {disabled && disabledText ? (
        <Text>{disabledText}</Text>
      ) : (
        <>
          <Text hideOnMobile>Drag and Drop image files here</Text>
          <Text hideOnMobile>OR</Text>
          <Upload mb={bottomMargin || 0}>CLICK HERE TO UPLOAD</Upload>
        </>
      )}
    </UploaderBox>
  )
}
export const Uploader = (props: Props) => (
  <DropZone {...props}>
    <UploadContent {...props} />
  </DropZone>
)
export { DropZone }