import styled from 'styled-components'
import { width, space } from 'styled-system'
import { lighten } from 'polished'
export const Form = styled.form`
  padding: 40px 0;
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    margin: 0 20px;
  }
`

/* Fieldset for semantic html Form markup (BUG Flex doesn't work with Fieldset) */
export const Fieldset = styled.fieldset`
  width: 100%;
  margin: 0 0 25px;
  padding: 0;
  border: none;
  label {
    white-space: nowrap;
  }
`
export const Legend = styled.legend`
  font-size: ${(props) => props.theme.fontSizes[3]}px;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
export const Label = styled.label`
  font-size: ${(props) => props.theme.fontSizes[3]}px;
`
export const Input = styled.input`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  &[type='text'] {
    background: ${(props) => props.theme.colors.backgroundGray};
    padding: ${(props) => props.theme.space[2]}px;
    border: 1px solid ${(props) => props.theme.colors.borderLight};
    ${(props) =>
      props.error &&
      `
      background: ${lighten(0.4, props.theme.colors.red)};
      border: 1px solid ${props.theme.colors.red};
    `};
  }
  &[type='checkbox'] {
    background: ${(props) => props.theme.colors.backgroundGray};
  }
  &:focus {
    outline: none;
  }
`
export const Textarea = styled.textarea`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  background: ${(props) => props.theme.colors.backgroundGray};
  padding: ${(props) => props.theme.space[2]}px;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  resize: none;
  ${(props) =>
    props.error &&
    `
    background: ${lighten(0.4, props.theme.colors.red)};
    border: 1px solid ${props.theme.colors.red};
  `};
  &:focus {
    outline: none;
  }
`
export const Error = styled.span`
  color: ${(props) => props.theme.colors.red};
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  padding: 0;
  margin: 0;
`
export const Text = styled.span`
  font-size: 20px;
`
// Custom Checkbox
export const Checkbox = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: 15px;
  min-width: 15px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  div[data-type='checkbox-text'] {
    flex: 1;
    margin-left: ${(props) => props.theme.space[2]}px;
  }
  ${(props) => props.readOnly && `pointer-events: none;`}
  ${width};
  ${space};
  [data-type='checkbox-text'] {
    display: flex;
    gap: 16px;
  }
`
export const CheckboxV2Component = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: 15px;
  min-width: 15px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  div[data-type='checkbox-text'] {
    flex: 1;
  }
  ${(props) => props.readOnly && `pointer-events: none;`}
  ${width};
  [data-type='checkbox-text'] {
    display: flex;
    gap: 16px;
  }
`
export const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 15px;
  width: 15px;
  border: 1px solid #d8d8d8;
`
export const CheckMark = styled.span`
  width: 4px;
  height: 7px;
  border: solid #231f20;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`
// Custom Radio
export const Radio = styled.label`
  font-size: 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 15px;
  min-width: 15px;
  width: auto;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  ${Icon} {
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }
  ${Text} {
    margin-left: ${(props) => props.theme.space[2]}px;
  }
  ${(props) => props.readOnly && `pointer-events: none;`}
  ${width};
  ${space};
  [data-type='radio-text'] {
    display: flex;
    gap: 16px;
  }
`
export const Circle = styled.span`
  width: 7px;
  height: 7px;
  background: #231f20;
  border-radius: 50%;
`
export const InputLabel = styled.div`
  position: relative;
  margin: 5px 0 20px;

  &[data-error] {
    input {
      border: 1px solid #cc3333;
    }
  }

  /* Handle Parent Containers */
  [data-error] & {
    input {
      border: 1px solid #cc3333;
    }
  }

  label {
    position: absolute;
    top: -3px;
    left: 0;
    pointer-events: none;
    transform-origin: top left;
    transition: 0.1s ease-in-out transform;
    color: #999999;
    width: 100%;
    height: 100%;
    margin: 20px 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  input {
    width: 100%;
    height: 50px;
    padding: 20px 15px 10px;
    margin: 0;
    border: 1px solid #dddddd;
    &:not([value='']) + label,
    &:focus + label {
      transform: translateY(-10px) scale(0.7);
    }
    &:focus {
      border: 1px solid #333333;
      outline: none;
    }
    ::placeholder {
      visibility: hidden;
    }
    ::-moz-placeholder,
    ::-webkit-input-placeholder {
      visibility: hidden;
      color: transparent;
    }
    :-ms-input-placeholder {
      color: transparent;
    }
  }

  textarea {
    font-size: 16px;
    width: 100%;
    min-height: 100px;
    max-height: 200px;
    padding: 25px 15px 10px;
    resize: vertical;
    border: 1px solid #dddddd;
    &:not(:empty) + label,
    &:focus + label {
      transform: translateY(-10px) scale(0.7);
      white-space: nowrap;
    }
    &:focus {
      border: 1px solid #333333;
      outline: none;
    }
    ::placeholder {
      visibility: hidden;
    }
    ::-moz-placeholder,
    ::-webkit-input-placeholder {
      visibility: hidden;
      color: transparent;
    }
    :-ms-input-placeholder {
      color: transparent;
    }
  }

  span[data-type='error'] {
    position: absolute;
    left: 0;
    bottom: -20px;
    padding: 0;
    font-size: 12px;
    text-align: left;
    color: #cc3333;
  }

  [data-type='char-limit'] {
    position: absolute;
    right: 0;
    bottom: -17px;
    text-align: right;
    color: #999999;
    font-size: 12px;
    margin: 5px 0;
  }
`
