"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.algoliaArtworkData = algoliaArtworkData;
exports.visiiArtworkData = visiiArtworkData;
exports.additionalImage = additionalImage;
require("types/Algolia");
require("types/Artwork");
require("types/Visii");
require("types/Upload");
const category_1 = require("../category");
const { slugifyTitle } = require('lib/slugify');
// API's use IMAGEPROC new URL/Domain
const oldImageProcDomain = 'assets.saatchiart.com';
const newImageProcDomain = 'images.saatchiart.com';
// API's return large image -6.jpg, square is -23.jpg
const defaultImage = '-6.jpg';
const squareImage = '-23.jpg';
const getArtworkLinks = (data) => {
    const { artwork_title: artworkTitle, legacy_art_id: legacyArtId, artId, category, id_user: idUser, original_status: originalStatus, prints_availability: printsAvailability, } = data || {};
    const hasPrints = printsAvailability !== 'none';
    const title = slugifyTitle(`${(0, category_1.getProductCategoryUrl)(category)}-${artworkTitle}`);
    const path = `${title}/${idUser}/${legacyArtId || artId}`;
    const originalUrl = `/art/${path}/view`;
    const printUrl = `/print/${path}/view`;
    let productUrl = originalUrl;
    if (hasPrints && originalStatus === 'unavail') {
        productUrl = printUrl;
    }
    return {
        originalUrl,
        printUrl,
        productUrl,
    };
};
function algoliaArtworkData(data) {
    const artistName = data.first_name && data.first_name ? `${data.first_name} ${data.last_name}` : '';
    const { originalUrl, printUrl, productUrl } = getArtworkLinks(data);
    return {
        api: 'algolia',
        artistId: data.id_user,
        artistName,
        artworkId: data.artId,
        artworksUrl: data.artist_artworks_url,
        aspectRatio: data.aspect_ratio || 1,
        category: data.category,
        imageBaseUrl: data.image_url ? data.image_url.replace(/-\d.jpg$/, '') : '',
        // `...1129409-6.jpg` => `...1129409`
        imageUrl: data.image_url
            .replace(oldImageProcDomain, newImageProcDomain)
            .replace(defaultImage, squareImage),
        isSeoValid: data.is_seo_valid || false,
        minPrintPrice: Number(data.min_print_price) / 100 || '',
        originalStatus: data.original_status,
        price: Number(data.price) / 100 || '',
        originalUrl,
        printUrl,
        productUrl,
        productPage: !data.price && data.has_prints ? printUrl : originalUrl,
        profileUrl: data.profile_url,
        sku: data.sku,
        title: data.artwork_title,
    };
}
function visiiArtworkData(results) {
    const { metadata } = results;
    // Split product page url
    // Uses /artdetail (or /art) or /print as the base path later on
    // TODO: setup /artdetail and /print paths
    // to re-render components with Next router
    const productPath = metadata.product_page.split('/art/')[1];
    const productPage = `/${metadata.price ? 'art' : 'print'}/${productPath}`;
    const stylesString = typeof metadata.style === 'string' ? metadata.style : '';
    return {
        api: 'visii',
        artistName: metadata.artist_name,
        artworkId: metadata.artwork_id,
        artworksUrl: metadata.artwork_url,
        aspectRatio: metadata.width / metadata.height || 1,
        category: metadata.category,
        imageUrl: metadata.image_url
            .replace(oldImageProcDomain, newImageProcDomain)
            .replace(defaultImage, squareImage),
        isSeoValid: true,
        // Vissi Artwork is VALID
        minPrintPrice: metadata.starting_print_price || '',
        price: metadata.price,
        productUrl: productPage,
        productPage,
        productPath,
        profileUrl: metadata.artist_url,
        sku: metadata.sku,
        styles: stylesString === '' ? [] : stylesString.split(','),
        subject: metadata.subject,
        title: metadata.title,
    };
}
function additionalImage(data, index) {
    const { thumbnailUrl: thumb, description } = data;
    // $FlowBug - Regex Complains about Escaping Characters
    const prefix = thumb.split(/.com/)[1].replace(/-(\d+).jpg$/, '-');
    return {
        description,
        id: index,
        order: index,
        prefix,
        thumb,
    };
}
