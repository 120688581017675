import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const Follow = styled.button`
  width: 96px;
  height: 38px;
  border: 1px solid #dcdcdc;
  background: none;
  color: #333333;
  margin-top: 24px;
  cursor: pointer;
  &[data-blocked] {
    pointer-events: none;
    opacity: 0.5;
  }
  [data-type='follow-text'] {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 992px) {
    &[data-style='following'] {
      &:hover {
        border: 1px solid #ef542c;
        background-color: #ef542c33;
        color: #ef542c;
        cursor: pointer;
        span {
          display: none;
        }
      }
      &:hover:before {
        content: 'Unfollow';
      }
    }
  }
`
