import React from 'react'
import Hero from '../components/Hero'
import CuratorInfo from '../components/userInfo/CuratorInfo'
import Collections from '../components/Collections'
import ArtAdvisory from '../components/ArtAdvisory'
import Favorites from '../components/Favorites'
import { Layout } from '../components/styles'

const Component = (): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <Layout>
      <Hero />
      <div data-section='main-content'>
        <CuratorInfo />
        <div data-section='right-col'>
          <Favorites />
          <Collections />
          <ArtAdvisory />
        </div>
      </div>
    </Layout>
  )
}

export default Component