import React from 'react'

const Component = ({ handleOpenCreateCollectionsModal }) => {
  return (
    <>
      <button type='button' onClick={handleOpenCreateCollectionsModal}>
        Create New Collection
      </button>
    </>
  )
}

export default Component
