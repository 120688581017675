import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getUserID, getIsAdmin } from 'selectors/user/session'
import { getSelectedCurrency } from 'selectors/user/cookie'
import { getLocation } from 'selectors/shared/location'
import { getAccountData, getFullName, getPageParamUserId, getAccountUserID } from '../selectors'
const mapStateToProps = createStructuredSelector({
  accountData: getAccountData,
  sessionUserID: getUserID,
  selectedCurrency: getSelectedCurrency,
  isAdmin: getIsAdmin,
  fullName: getFullName,
  location: getLocation,
  pageParamUserID: getPageParamUserId,
  artistID: getAccountUserID,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  accountData: Record<string, any>
  sessionUserID: number
  selectedCurrency: Record<string, any>
  isAdmin: boolean
  fullName: string
  location: string
  pageParamUserID: string
  artistID: number
}
export default connect(mapStateToProps, mapDispatchToProps)