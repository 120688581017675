import React, { useEffect, useState } from 'react'
import { updateArtworkInMyCollection } from './helpers'
import { TickIcon } from './styles'

const Item = ({
  addMessage,
  artworkId,
  artworkList,
  userCollectionId,
  title,
}): React.ReactElement<React.ComponentProps<any>, any> => {
  const [isUpdatingAPI, handleIsUpdatingAPI] = useState(false)
  const [isSelected, handleIsSelected] = useState(false)
  useEffect(() => {
    handleIsSelected(artworkList.includes(userCollectionId))
  }, [artworkList])
  return (
    <button
      type='button'
      data-type='collection-item'
      onClick={async () => {
        handleIsSelected(!isSelected)
        handleIsUpdatingAPI(true)
        const response = await updateArtworkInMyCollection({
          artworkId,
          userCollectionId,
          action: isSelected ? 'REMOVE' : 'ADD',
        })

        if (response.type === 'error') {
          addMessage([response])
          handleIsSelected(isSelected)
        }

        handleIsUpdatingAPI(false)
      }}
      {...(isSelected
        ? {
            'data-selected': true,
          }
        : {})}
    >
      <p>{title}</p>
      {isUpdatingAPI && <div data-type='loader' />}
      {isSelected && <TickIcon data-type='tick-icon' />}
    </button>
  )
}

const Component = ({
  addMessage,
  artworkId,
  collectionList,
  artworkList,
}: Record<string, any>): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <div data-item='collection-list'>
      {!collectionList.length ? (
        <p data-type='no-results'>No Results</p>
      ) : (
        collectionList.map(({ userCollectionId, title }) => (
          <Item
            addMessage={addMessage}
            artworkId={artworkId}
            artworkList={artworkList}
            key={userCollectionId}
            userCollectionId={userCollectionId}
            title={title}
          />
        ))
      )}
    </div>
  )
}

export default Component