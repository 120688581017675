import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Actions as AuthenticationActions } from 'actions/authentication'
import { isAuthenticated, getFavorites, getUserID } from 'selectors/user/session'
import { getFavoriteSelected } from 'selectors/user/favorite'
import type { Modals } from 'types/Authentication'
import { Actions as FavoriteActions } from 'actions/user/favorite'
const mapStateToProps = createStructuredSelector({
  isAuthenticated,
  favorites: getFavorites,
  userId: getUserID,
  selectedFavorite: getFavoriteSelected,
})

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload: Modals) => dispatch(AuthenticationActions.SHOW_MODAL(payload)),
  favoriteItem: (favorite: { id: number; favorite: boolean }) => {
    dispatch(FavoriteActions.UPDATE(favorite))
  },
})

export type ConnectorProps = {
  isAuthenticated: boolean
  favorites: Array<number>
  favoriteItem: (...args: Array<any>) => any
  userId: number
  selectedFavorite: number
  showModal: (...args: Array<any>) => any
}
export default connect(mapStateToProps, mapDispatchToProps)