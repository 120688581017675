import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getUserID } from 'selectors/user/session'
import { getInsideTheStudioImages, getAccountUserID } from '../selectors'
const mapStateToProps = createStructuredSelector({
  additionalImages: getInsideTheStudioImages,
  accountUserID: getAccountUserID,
  sessionUserID: getUserID,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  additionalImages: Array<Record<string, any>>
  accountUserID: number
  sessionUserID: number
}
export default connect(mapStateToProps, mapDispatchToProps)