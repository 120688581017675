import React from 'react'
import { Search, Input, MagGlass } from './styles'
type Props = {
  placeholder?: string
  name?: string
  value: string
  label?: string
  onChange: (...args: Array<any>) => any
}
export default (props: Props) => {
  const { name = 'search', placeholder = 'Search...', value = '', onChange, label = '' } = props
  return (
    <Search>
      <Input
        autoComplete='off'
        name={name}
        onChange={(ev) => onChange(ev.target.value)}
        placeholder={placeholder}
        type='text'
        value={value}
        aria-label={`Search ${label}`}
      />
      <MagGlass />
    </Search>
  )
}