import fetch from 'isomorphic-fetch'
import { parseJSON, deleteOptions, getOptions, postOptions } from 'apis/helpers'
import type { Follow } from 'types/User/Follow'
import 'types/User/Follow'
const domain = process.env.SITE_URL || ''
export async function updateFollowApi(payload: {
  targetUserId: number
  following: boolean
}): Promise<Follow> {
  const { targetUserId, following } = payload
  const url = `${domain}/api/follow-user/${targetUserId}`
  const options = following ? postOptions : deleteOptions
  const response = await fetch(url, options)
  const json = await parseJSON(response, 'updateFollowApi')
  return json
}
// Defaults to GET request
export async function fetchFollowApi(): Promise<Follow> {
  const url = `${domain}/api/follow-user`
  const options = getOptions
  const response = await fetch(url, options)
  const json = await parseJSON(response, 'fetchFollowApi')
  return json
}
// follow apis for artist search
export async function updateArtistSearchFollowApi(payload: {
  targetUserId: number
  following: boolean
}): Promise<Follow> {
  const { targetUserId, following } = payload
  const url = `/api/follow-user/${targetUserId}`
  const options = following ? postOptions : deleteOptions
  const response = await fetch(url, options)
  const json = await parseJSON(response, 'updateFollowApi')
  return json
}
// Defaults to GET request
export async function fetchArtistSearchFollowApi(): Promise<Follow> {
  const url = `/api/follow-user`
  const options = getOptions
  const response = await fetch(url, options)
  const json = await parseJSON(response, 'fetchFollowApi')
  return json
}
// follow api palette endpoint
export async function updateUserFollowApi(payload: {
  targetUserId: number
  following: boolean
  userID: number
}): Promise<Follow> {
  const { targetUserId, following, userID } = payload
  const url = `/easel_api/user/${userID}/follow-user/${targetUserId}`
  // const url = `/api/follow-user/${targetUserId}`
  const options = following ? postOptions : deleteOptions
  const response = await fetch(url, options)
  const json = await parseJSON(response, 'updateUserFollowApi')
  return json
}