import fetch from 'isomorphic-fetch'
import type { PromisePayload } from 'apis/helpers'
import { postOptions, deleteOptions, parseJSON } from 'apis/helpers'
export async function fetchFavoritesApi(): Promise<any> {
  const url = `/account/my-favorites`
  const options = {
    credentials: 'include',
  }
  const response = await fetch(url, options)
  const json = await parseJSON(response, 'fetchFavoritesApi', []) // Default Response - Empty Array

  return json
}
export async function updateFavoriteApi(data: {
  id: number
  userId: number
  favorite: boolean
}): Promise<boolean> {
  // const url = `https://www.qa.saatchiart.com/account/favorite`
  const url = `/account/favorite`
  const { userId, id, favorite } = data
  const formData = new FormData()
  formData.append('id', `${userId}`)
  formData.append('id_user_art', `${id}`)
  formData.append('favorite', `${favorite.toString()}`)
  formData.append('format', 'json')

  if (favorite) {
    formData.append('_method', '_delete')
  }

  const options = {
    credentials: 'include',
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest', // For legacy isXmlHttpRequest check
    },
    // mode: 'no-cors',
    body: formData,
  }
  const response = await fetch(url, options)
  return response.status === 200
}
export const postMyFavorite = async (artworkId: number): Promise<PromisePayload> => {
  const response = await fetch(`/easel_api/my-favorites/${artworkId}`, postOptions)
  const json = await parseJSON(response, 'postMyFavorite')
  return json
}
export const deleteMyFavorite = async (artworkId: number): Promise<PromisePayload> => {
  const response = await fetch(`/easel_api/my-favorites/${artworkId}`, deleteOptions)
  const json = await parseJSON(response, 'deleteMyFavorite')
  return json
}