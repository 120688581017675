import React from 'react'
import SALink from 'components/SALink'
import { BulbIcon } from '../styles'

const Component = (): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <div data-type='no-results'>
      <BulbIcon />
      <p>This is a new artist shop in the making.</p>
      <p>Check back for updates.</p>
      <SALink title='Browse Artworks' href='/all' data-type='browse-link'>
        browse other artworks
      </SALink>
    </div>
  )
}

export default Component