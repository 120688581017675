import React from 'react'
import { CURATOR } from 'lib/user/constants'
import Curator from './Curator'
import Artist from './Artist'
import type { ConnectorProps as LayoutProps } from '../connectors/Layout'
import LayoutConnector from '../connectors/Layout'

const Component = ({
  accountType,
}: LayoutProps): React.ReactElement<React.ComponentProps<any>, any> => {
  return <>{accountType === CURATOR ? <Curator /> : <Artist />}</>
}

export default LayoutConnector(Component)