import React from 'react'
import { flagPositions } from 'styles/flags'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = {
  state: string
  city: string
  country: string
  countryCode: string
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { state, city, country, countryCode } = props

  if (!state && !country && !city) {
    return null
  }

  const label = [city, state, country].filter(Boolean).join(', ')
  return (
    <div data-type='user-location-section'>
      {country && (
        <i
          data-type='flag-icon'
          style={flagPositions[(countryCode || '').toLowerCase()]}
          title={country}
        />
      )}
      <SATypography data-type='user-location' variant={SATypographyVariant.SMALL}>
        {label}
      </SATypography>
    </div>
  )
}

export default Component
