"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchArrayOfObjects = exports.searchArray = exports.searchText = void 0;
const blackListCharacters = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
// Basic RegExp(Case Insensitive)- Checker to find SEARCH in string
const searchText = (search = '', text = '') => {
    const words = search
        .replace(blackListCharacters, '')
        .replace(/[\s\d]+/g, ' ')
        .split(/[ ,]+/);
    let regExpString = '';
    words.map((word) => (regExpString += `(?=.*${word})`));
    const regExp = RegExp(regExpString, 'i');
    const match = text.match(regExp);
    return !!match;
};
exports.searchText = searchText;
// Search ARRAY of STRINGS
const searchArray = (search, array = []) => array.filter((val) => {
    // Handle `Object.entries` or Array< Array<*> >
    if (Array.isArray(val)) {
        const match = val.filter((subVal) => (0, exports.searchText)(search, subVal)) || [];
        return !!match.length;
    }
    else {
        return (0, exports.searchText)(search, val);
    }
});
exports.searchArray = searchArray;
// Search ARRAY of OBJECT and check ATTRIBUTES
const searchArrayOfObjects = (search, array = [], attrs = []) => array.filter((obj) => {
    let match = false;
    attrs.forEach((attr) => {
        if ((0, exports.searchText)(search, obj[attr])) {
            match = true;
        }
    });
    return match;
});
exports.searchArrayOfObjects = searchArrayOfObjects;
