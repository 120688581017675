// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import MagGlassRaw from '../../svg/mag-glass.svg'
export const Search = styled.div`
  display: flex;
  outline: 0;
  border: 1px solid ${(props) => props.theme.colors.grayDark};
  align-items: center;
`
export const Input = styled.input`
  border: none;
  padding: ${(props) => props.theme.space[1]}px;
  background: transparent;
  flex-grow: 1;
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  color: ${(props) => props.theme.colors.default};
  width: 100%;
  &:focus {
    outline: 0;
  }
`
export const MagGlass = styled(MagGlassRaw)`
  height: 15px;
  width: 16.4219px;
  margin: 0 10px;
`