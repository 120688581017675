/*

This is the Artwork Favorite Login Hook.
Purpose: We need a new UI sequence that takes a non-authed
user through a modal login, then continues the action that they
intended to take on the artwork they clicked.

1. Click on Favorite icon and enter the onClickFavorite function
onClickFavorites flow:
  a. Establish the action the user initiated
  b. Set the artworkId passed in as SelectedFavorite in Redux
  c. Is the user logged in?
    1. If no, show the login modal and set the continueAfterLogin flag so we know to complete the favorite action after login.

2. When the user is confirmed logged in, set the shouldBlockEvents flag and move into the updateFavorites function
updateFavorites flow:
  a. Read in the SelectedFavorite prop from the component that called the hook. This will ensure that the only favorite completed is the final one touched.
  a. Establish the favorite direction (favorite/unfavorite)
  b. Use the updateFavoriteApi to push the Favorite action to legacy. The apiResponse will be a boolean.
  c. Use the favoriteItem dispatch to update local state with the change
  d. If the apiResponse from the updateFavoriteApi call is true,
    1. Announce success with a dataLayerpush
    2. Fire the onSuccess callback function with a payload showing the action result

*/
import { useState, useEffect } from 'react'
import { updateFavoriteApi } from 'apis/favorites'
import { useStore } from 'react-redux'
import { Actions as FavoriteActions } from 'actions/user/favorite'
type Props = {
  artworkId: number
  isAuthenticated: boolean
  onSuccess?: (...args: Array<any>) => any
  favorites: Array<number>
  favoriteItem: (...args: Array<any>) => any
  showModal: (...args: Array<any>) => any
  userId: number
  selectedFavorite: number
}
type HookProps = {
  formAction: string
  onClickFavorite: (...args: Array<any>) => any
  isFavorite: boolean
  shouldBlockEvents: boolean
}
const POST = 'post'
const DELETE = 'delete'

const useFavorite = (props: Props): HookProps => {
  const {
    artworkId,
    isAuthenticated,
    favorites,
    favoriteItem,
    onSuccess,
    showModal,
    userId,
    selectedFavorite,
  } = props
  const [isFavorite, handleIsFavorite] = useState(false) // Read favorites, updates on any REDUX update

  const [shouldBlockEvents, setShouldBlockEvents] = useState(false) // Block UI

  const [continueAfterLogin, handlecontinueAfterLogin] = useState(false) // Should we fire event after LOGIN/REGISTER success

  const safeArtworkId = parseInt(artworkId, 10) // artworkId is a string, convert to integer for match

  const safeSelectedFavorite = parseInt(selectedFavorite, 10)
  const formAction = isFavorite ? DELETE : POST
  const store = useStore()
  useEffect(() => {
    // This ends back favorite icon on/off status back to the
    // component calling the hook. Use the incoming artworkId here.
    if (favorites && favorites.length) {
      handleIsFavorite(favorites.includes(safeArtworkId))
    }
  }, [favorites])
  // Open Modal and Now Login
  useEffect(() => {
    if (continueAfterLogin) {
      // Make a call to update the EVENT
      if (safeSelectedFavorite === safeArtworkId) {
        handlecontinueAfterLogin(false)
        updateFavorites(safeSelectedFavorite)
      }
    }
  }, [isAuthenticated])

  const updateFavorites = async (activeArtwork) => {
    const safeActiveArtwork = parseInt(activeArtwork, 10)
    // LEGACY API CALL
    const apiResponse = await updateFavoriteApi({
      favorite: isFavorite,
      id: safeActiveArtwork,
      userId,
    })
    // Update local state
    favoriteItem({
      id: safeActiveArtwork,
      favorite: isFavorite,
    })

    // TODO - NEW Palette APIs - Problem w/ Session CAN NOT USE
    // We will let the API auth the user and pass back redirect flag
    // let apiResponse
    // if (action === DELETE) {
    //   apiResponse = await deleteMyFavorite(artworkId)
    // } else {
    //   apiResponse = await postMyFavorite(artworkId)
    // }
    // TODO - Check success/response
    if (apiResponse) {
      const favoriteUpdate = !isFavorite
      setShouldBlockEvents(false)
      handleIsFavorite(favoriteUpdate)
      const event = formAction === DELETE ? 'unfavorite' : 'favorite'
      window.dataLayer.push({
        event: `${event} success`,
      })

      if (onSuccess) {
        onSuccess({
          shouldShowMessage: true,
          action: favoriteUpdate ? 'favorite' : 'unfavorite',
        })
      }
    }
  }

  const onClickFavorite = (ev: React.SyntheticEvent<any>): void => {
    const event = formAction === DELETE ? 'unfavorite' : 'favorite'
    window.dataLayer.push({
      event,
    })
    ev.preventDefault()

    if (shouldBlockEvents) {
      return
    }

    if (artworkId) {
      store.dispatch(FavoriteActions.SELECT_ARTWORK(artworkId))
    }

    if (!isAuthenticated) {
      showModal('login')
      handlecontinueAfterLogin(true)
      return
    }

    setShouldBlockEvents(true)
    updateFavorites(artworkId) // If logged in, no need to reference Redux for correct favorite.
  }

  return {
    formAction,
    onClickFavorite,
    isFavorite,
    shouldBlockEvents,
  }
}

export default useFavorite