"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOOL_TIP_TITLE = exports.COLLECTION_TITLE = exports.COLLECTIONS = exports.COLLECTOR = exports.ARTIST = exports.CURATOR = void 0;
exports.CURATOR = 'Curator';
exports.ARTIST = 'Artist';
exports.COLLECTOR = 'Collector';
exports.COLLECTIONS = 'Collections';
exports.COLLECTION_TITLE = {
    [exports.CURATOR]: 'Curated Collections',
    [exports.ARTIST]: 'Artist Series',
};
exports.TOOL_TIP_TITLE = {
    [exports.CURATOR]: 'community',
    [exports.ARTIST]: 'portfolio',
};
