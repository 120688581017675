import React from 'react'
import UserPreferencesConnector from 'connectors/UserPreferences'
import { convertWeightForClient, getWeightUnit } from './helper'
import { Weight } from './styles'
type Props = {
  weight: number
  userPreferencesMeasurementUnit: 'in' | 'cm'
}

const Component = (props: Props) => {
  const { weight, userPreferencesMeasurementUnit: measurementUnit } = props
  return (
    <Weight>
      {`${convertWeightForClient(weight, measurementUnit)} ${getWeightUnit(measurementUnit)}`}
    </Weight>
  )
}

export default UserPreferencesConnector(Component)